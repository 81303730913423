import React from 'react'
import { Button, GrayPara, Para, ParaPayment, Linkname, BodyWrapper } from './StyledComponents';
import DataTable, {createTheme} from 'react-data-table-component'
import FallingArrow from '../../assets/Images/falling-arrow.png'
import Trending from '../../assets/Images/trending-arrow.png'
import { useThemeContext } from '../../Context/ThemeContext';
import DataLoader from '../../Component/Loader/DataLoader';
import { darkTheme, lightTheme } from '../../Theme/theme';
import { IoMdThumbsUp } from "react-icons/io";
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const columns = (currency) => {
    return (
        [
            {
                name: 'Buy From',
                selector: row => {
                    return <div className='d-flex flex-column m-0'>
                        <Linkname className='m-0 text-decoration-underline' as={Link} to={`/dashboard/user/${row?.user?._id}`}>{row?.user?.nickNames || "Sumit"}</Linkname>
                        <GrayPara className='m-0'>{ row?.user_active_since?.includes('0') ?  <p className='mb-0 text-success fw-bold'>Online</p>: `Last Seen ${row?.user_active_since} ago`}</GrayPara>
                        {(row?.user?.meanpercentage >= 0) && <div className='d-flex mt-1 align-items-center'>
                            <IoMdThumbsUp color='blue' size={25} /> 
                            <span className='mb-0 text-secondary fw-bold'>{row?.user?.meanpercentage}%</span>
                        </div>}
                    </div>
                },
                sortable: true,
                width: '20%'
            },
            {
                name: 'Pay With',
                selector: row => {
                    return <div className='d-flex flex-column m-0'>
                        <ParaPayment className='m-0 text-uppercase'>{row?.payment_method}</ParaPayment>
                    </div>
                },
                sortable: true,
                width: '20%'
            },
            {
                name: 'Minimum/Maximum Amount',
                selector: row => {
                    return <div className='d-flex flex-column flex-sm-row gap-1 m-0 align-items-center'>
                        <Para className='mb-0'>{row?.trade_limit?.min}-{row?.trade_limit?.max}</Para>
                        <Para className='mb-0'>{row?.preffered_currency?.symbol}</Para>
                    </div>
                },
                sortable: true,
                width: '20%'
            },
            {
                name: 'Avg. Release Time',
                selector: (row) => {
                    return <div>
                        <Para>{row?.avarageReleaseTime ? `${row?.avarageReleaseTime} minutes` : 'New Offer'}</Para>
                    </div>
                },
                sortable: true,
            },
            {
                name: `Price per ${currency?.name}`,
                selector: row => {
                    let amount = 1 - (1 * row?.offer_margin / 100)
                    return (
                        <div className='d-flex flex-column gap-1 my-2'>
                            <ParaPayment className='m-0'>{Number(row?.crypto_price_with_margin).toFixed(3)}</ParaPayment>
                            <Para style={{ fontSize: '9px' }} className='m-0'>
                                1 USD = {amount} USD of <span className='text-uppercase'>{currency?.symbol}</span>
                                <span className="mx-1 pb-3">
                                    <img height='12px' src={row?.offer_margin > 0 ? Trending : FallingArrow} alt="trending" className="" />
                                    {row.offer_margin}%
                                </span>
                            </Para>
                            <div className="d-flex gap-2 justify-content-end">
                                <Link to={`/dashboard/offer/${row?._id}`}>
                                    <Button className="buythisofferbutton d-flex align-items-center gap-2 px-3">
                                        Buy
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    )
                },
                sortable: true,
            },
        ]
    )
}
export default function List({ offersList, loader, currency }) {
    const {isDarkTheme} = useThemeContext();
    createTheme('solarized', {
        background: {
          default: `${isDarkTheme ? "#0c0c0c" : 'white'}`
        },
        action: {
          hover: 'rgba(0,0,0,.08)',
        },
        text: {
            secondary: `${isDarkTheme ? "white" : 'black'}`
          },
      });
    const customStyles = {
        header: {
            style: {
                fontSize: '20px',
                color: '#0B0E2C',
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: '600',

            },
        },
        headRow: {
            style: {
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontSize: '12px',
                ...( isDarkTheme ? {borderTop: '1px solid #302f30'}:
                { border: '1px solid lightgray'}),
                color: `${isDarkTheme ? "#e9e9e9":'#6F7182'}`,
                backgroundColor: `${isDarkTheme ? "#0c0d0d":"#F7F9FC"}`,
                textTransform: 'uppercase'
            },
        },
        rows: {
            style: {
                border: `${({theme})=>theme.border}`,
                color: `${isDarkTheme && 'white'}`,
                backgroundColor: `${isDarkTheme && 'rgba(8, 9, 14, 0.96)'}`,
                boxShadow: `${isDarkTheme ? darkTheme.boxshadow : lightTheme.boxshadow}`,
                borderRadius: '20px',
                marginTop: '5px',
                marginBottom: '5px',
                '&:not(:last-of-type)': {
                    borderBottom: 'none',
                },
            }
        },
        headCells: {
            style: {
                display: 'flex',
                alignItems: 'center'
            },
        },
        cells: {
            style: {
                display: 'flex',
                alignItems: 'center'
            },
        }
    }

    return (
        <BodyWrapper className='filterofferlistfortour' >
            <DataTable
                columns={columns(currency)}
                data={offersList}
                progressPending={loader}
                progressComponent={<DataLoader />}
                responsive={true}
                customStyles={customStyles}
                pagination={true}
                theme='solarized'
            />
        </BodyWrapper>
    )
}
List.propTypes = {
    loader: PropTypes.bool.isRequired,
    currency: PropTypes.string.isRequired,
    offersList: PropTypes.shape().isRequired,
};

// List.propTypes = {
//     loader: PropTypes.bool.isRequired,
//     currency: PropTypes.object.isRequired,
//     offersList: PropTypes.array.isRequired,
// };