import React, { useEffect, useState } from 'react'
import './User.css'
import { CiCircleCheck } from "react-icons/ci";
import { MdEmail } from "react-icons/md";
import { useNavigate, useParams, Link } from 'react-router-dom';
import styled from 'styled-components';
import { CardContainer, Container, Label, Para, Para1, ProfileHeading, Label1, ProfileImage, Small } from './StyledComponent'
import { GrLocation } from "react-icons/gr";
import { CgProfile } from 'react-icons/cg';
import DataTable, { createTheme } from 'react-data-table-component';
import { MdPhoneAndroid } from 'react-icons/md'
import { API_URLs } from '../../Utils/ApiUrls';
import Trending from '../../assets/Images/trending-arrow.png'
import FallingArrow from '../../assets/Images/falling-arrow.png'
import axios from 'axios';
import { Linkname, ParaPayment } from '../Buy/StyledComponents';
import { GrayPara } from '../Sell/StyledComponents'
import { IoMdThumbsUp } from "react-icons/io";
import { FaArrowLeftLong } from "react-icons/fa6";
import moment from 'moment';
import { useThemeContext } from '../../Context/ThemeContext';
import { GoLocation, GoVerified } from "react-icons/go";
import { useAPIContextHook } from '../../Context/ApiContext';
import { useAuthContextHook } from '../../Context/AuthContext';
import { Rating } from 'react-simple-star-rating'

const Button = styled.button`
    color: white;
    border: none;
    background-color: rgb(20, 118, 255);
    font-size: 13px;
    display: flex;
    align-items: center;
    padding: 5px 13px;
    text-decoration: none;
    border-radius: 20px;
`
const Div = styled.div`
    background: ${({ theme }) => theme.body === "#F7F9FC" ? 'white' : theme.body};
    border: ${({ theme }) => theme.border};
    box-shadow: ${({ theme }) => theme.boxshadow};
    margin-top: 15px;
    padding: 15px;
    border-radius: 20px; 
`
const Headings = styled.div`
    color: rgba(111, 113, 130, 1);
    font-weight: 700;
    border: 1px solid
    font-size: 16px;
`
const Card = styled.div`
    border-radius: 20px;
    background: ${({ theme }) => theme.body === "#F7F9FC" ? 'white' : theme.body};
    border: ${({ theme }) => theme.border};
    box-shadow: ${({ theme }) => theme.boxshadow};
`

const ProfileDetails = ({ profileData }) => {
    const [userDetails, setUserDetails] = useState(null)
    const [phoneNumber, setPhoneNumber] = useState('')
    const { phoneCode } = useAPIContextHook();

    useEffect(() => {
        const getUserDetails = () => {
            axios.get(API_URLs.getUser, {
                headers: {
                    accept: 'application/json',
                    "Authorization": `Bearer ${localStorage.getItem('jwt')}`
                }
            })
                .then((res) => {
                    setPhoneNumber(res?.data?.user?.phoneCode)
                    setUserDetails(res.data?.user);
                    getLocation()
                })
                .catch((error) => { });
        };
        getUserDetails()
    }, [])
    
    const getLocation = () => {
        const cntryCode = phoneCode?.filter((data) => {
            return (data.value === phoneNumber)
        });
        let countryName = cntryCode?.[0]?.label?.split(' ')?.[0];
        return countryName || '';
    }
    getLocation()

    console.log('userDetails', userDetails)

    return (
        <CardContainer className='p-3 flex-grow-1'>
            <div className='d-flex gap-2 flex-column pt-3'>
                <Label>Joined On</Label>
                <div className='d-flex align-items-center gap-2'>
                    <CgProfile color='#1476FF' />
                    <Para className='m-0'>{moment(profileData[0]?.createdAt).format("YYYY-MM-DD")}</Para>
                </div>
                <hr className='my-2'></hr>
            </div>

            <div className='d-flex gap-2 flex-column pt-3'>
                <Label>Verification</Label>
                <div className='d-flex align-items-center gap-2'>
                    {profileData[0]?.isEmailVerified ? <GoVerified color='#1476FF' /> : <GoVerified color='#1476FF' />}
                    <Para className='m-0'>Email</Para>
                </div>
                <div className='d-flex align-items-center gap-2'>
                    {profileData[0]?.isMobileVerified ? <GoVerified color='#1476FF' /> : <GoVerified color='#1476FF' />}
                    <Para className='m-0'>Phone</Para>
                </div>
                <hr className='my-2'></hr>
                <div className='d-flex flex-column gap-1'>
                    <Label>Location</Label>
                    <p><GoLocation color='#1476FF' /> {getLocation()}</p>
                </div>
            </div>
        </CardContainer>
    )
}
export default function User() {
    const { handleSetNavbarHeading, isDarkTheme } = useThemeContext()

    const columns = [
        {
            name: 'Sell To',
            selector: row => {
                return <div className='d-flex flex-column m-0'>
                    <Linkname className='m-0 text-decoration-underline'>{profileData[0]?.nickNames}</Linkname>
                    <GrayPara className='m-0'>{row?.user_active_since ? row?.user_active_since.includes('0') ? <p className='mb-0 text-success fw-bold'>Online</p> : `Last Seen ${row?.user_active_since} ago` : ''}</GrayPara>
                    {profileData[0]?.meanpercentage > 0 && <div className='d-flex mt-1 align-items-center'>
                        <IoMdThumbsUp color='blue' size={25} />
                        <span className='mb-0 text-secondary fw-bold'>{profileData[0]?.meanpercentage}%</span>
                    </div>}
                </div>
            },
            sortable: true,
            wrap: true,
        },
        {
            name: 'Get Paid With',
            selector: row => {
                return <div className='d-flex flex-column m-0'>
                    <ParaPayment className='m-0'>{row?.payment_method}</ParaPayment>
                </div>
            },
            sortable: true,
            wrap: true,
        },
        {
            name: 'Minimum/Maximum Amount',
            selector: row => {
                return <div className='d-flex flex-column flex-md-row gap-1 m-0 align-items-center'>
                    <Para className='mb-0'>{row?.trade_limit?.min}-{row?.trade_limit?.max}</Para>
                    <Para className='mb-0'>{row?.preffered_currency?.symbol}</Para>
                </div>
            },
            sortable: true,
        },
        {
            name: `Avg. Paid/Release Time`,
            selector: row => {
                return <div className='d-flex m-0 align-items-center gap-2'>
                    <Para className='m-0'>{row?.averagePaidTime ? `${row?.averagePaidTime} minutes` : 'Instant'}</Para>
                </div>
            },
            sortable: true,
            wrap: true,
        },
        {
            name: `Price`,
            selector: row => {
                let amount = 1 - (1 * row?.offer_margin / 100)
                return (
                    <div className='d-flex flex-column gap-1 my-2'>
                        <ParaPayment className='m-0'>{Number(row?.crypto_price_with_margin).toFixed(3)}</ParaPayment>
                        <Para style={{ fontSize: '9px' }} className='m-0'>
                            1 USD = {amount} USD <span className='text-uppercase'></span>
                            <span className="mx-1 pb-3">
                                <img height='12px' src={row?.offer_margin > 0 ? Trending : FallingArrow} alt="trending" />
                                {row.offer_margin}%
                            </span>
                        </Para>
                        <div className="d-flex gap-2 justify-content-end">
                            <Link to={`/dashboard/offer/${row?._id}`}>
                                <Button className="d-flex align-items-center gap-2 px-3">
                                    {row?.offer_type}
                                </Button>
                            </Link>
                        </div>
                    </div>
                )
            },
        },
    ]
    const feedColumn = [
        {
            name: 'User',
            selector: (row) => {
                return <div className='d-flex flex-column m-0 my-3'>
                    <Linkname className='m-0 text-decoration-underline' as={Link} target="_blank" to={`/dashboard/user/${row?.feedbackBy?._id}`}>{row?.feedbackBy?.nickNames}</Linkname>
                    {row?.feedbackBy?.meanpercentage > 0 && <div className='d-flex mt-1 align-items-center'>
                        <IoMdThumbsUp color='blue' size={25} />
                        <span className='mb-0 text-secondary fw-bold'>{row?.feedbackBy?.meanpercentage}%</span>
                    </div>}
                </div>
            },
            sortable: true,
            wrap: true,
        },
        {
            name: 'Payment Method',
            selector: (row) => {
                return <div className='d-flex flex-column m-0'>
                    <ParaPayment className='m-0'>{row?.tradeId?.payment_method ? row?.tradeId?.payment_method : "Bank Transfer"}</ParaPayment>
                </div>
            },
            sortable: true,
            wrap: true,
        },
        {
            name: 'Date',
            selector: (row) => <div className='d-flex flex-column m-0'>
                <ParaPayment className='m-0'>{moment(row?.createdAt).format('DD-MM-YYYY')}</ParaPayment>
            </div>
        },
        {
            name: 'Ratings',
            selector: (row) => <div>
                {/* <ParaPayment className='m-0'>{row?.rating}</ParaPayment> */}
                <ParaPayment className='m-0'>
                    <Rating allowFraction={true} size={20} readonly={true} initialValue={row?.rating} />
                </ParaPayment>
            </div>
        },
        {
            name: 'Feedback',
            selector: (row) => <div className='d-flex flex-column m-0 text-capitalize'>
                <ParaPayment className='m-0 text-wrap'>{row?.feedback ? row?.feedback :'__'} </ParaPayment>
            </div>
        }
    ]
    createTheme('solarized', {
        background: {
            default: `${isDarkTheme ? "#0c0c0c" : 'white'}`
        },
        action: {
            hover: 'rgba(0,0,0,.08)',
        },
        text: {
            secondary: `${isDarkTheme ? "white" : 'black'}`
        },
    });
    const customStyles = {
        header: {
            style: {
                fontSize: '20px',
                color: '#0B0E2C',
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: '600',
            },
        },
        headRow: {
            style: {
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontSize: '12px',
                ...(isDarkTheme ? { borderTop: '1px solid #302f30' } :
                    { border: '1px solid lightgray' }),
                color: `${isDarkTheme ? "#e9e9e9" : '#6F7182'}`,
                backgroundColor: `${isDarkTheme ? "#0c0d0d" : "#F7F9FC"}`,
                textTransform: 'uppercase'
            },
        },
        rows: {
            style: {
                color: `${isDarkTheme && 'white'}`,
                backgroundColor: `${isDarkTheme && 'rgba(8, 9, 14, 0.96)'}`,
                borderTop: '1px solid lightgray',
                '&:not(:last-of-type)': {
                    borderBottom: 'none',
                },
            }
        },
        headCells: {
            style: {
                display: 'flex',
                alignItems: 'center'
            },
        },
        cells: {
            style: {
                display: 'flex',
                alignItems: 'center',
                // justifyContent: 'center'
            },
        }
    }

    useEffect(() => {
        handleSetNavbarHeading('Public Profile')
    }, [])

    const [profileData, setProfileData] = useState([])
    const [offerData, setOfferData] = useState([])
    const [feedData, setFeedData] = useState([])
    const [buyOffer, setBuyOffer] = useState([])
    const [sellOffer, setSellOffer] = useState([])
    const [userTradeProfile, setUserTraderProfile] = useState({})
    const params = useParams();
    const navigate = useNavigate();
    const { phoneCode } = useAPIContextHook();

    const { id } = params;

    useEffect(() => {
        axios.get(API_URLs.publicprofile + '/' + id, {
            headers: {
                'accept': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('jwt')}`
            }
        })
            .then((res) => {
                setProfileData(res.data.user);
                setOfferData(res.data.userOffers);
                setFeedData(res.data.usersFeedback);
            })
            .catch((error) => {
                console.log('Error', error);
            })

    }, [])

    useEffect(() => {
        axios.get(`${API_URLs.getPublicUserTradeProfile}/${id}`, {
            headers: {
                'accept': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('jwt')}`
            }
        })
            .then((res) => {
                setUserTraderProfile(res?.data)
            })
            .catch((err) => {
            })
    }, [userTradeProfile.length])

    useEffect(() => {
        let buyofferdata = offerData.filter((offer) => {
            return offer.offer_type === 'buy' && !offer.isBlocked
        })
        setBuyOffer(buyofferdata)

        let sellofferdata = offerData.filter((offer) => {
            return offer.offer_type === 'sell' && !offer.isBlocked
        })
        setSellOffer(sellofferdata)

    }, [offerData])

    return (
        <Container className='container p-3'>
            <FaArrowLeftLong onClick={() => navigate(-1)} />
            <div className='d-flex flex-column flex-xl-row '>
                <div className='col-12 col-xl-8 p-3 d-flex flex-column d-xl-block gap-3 gap-xl-0'>
                    <CardContainer className='pb-3'>
                        <div className='d-flex flex-column flex-md-row justify-content-md-between align-items-md-center p-3'>
                            <div className='d-flex align-items-start gap-3 align-items-center'>
                                <div>
                                    {
                                        profileData[0]?.avatar ?
                                            <ProfileImage src={profileData[0]?.avatar} alt="" /> :
                                            <ProfileImage src={User} alt="" />
                                    }
                                </div>
                                <div className='d-flex flex-column pt-3'>
                                    <ProfileHeading className='m-0 d-flex flex-column'>{profileData[0]?.nickNames}
                                        <Small className='text-secondary'>{profileData[0]?.bio}</Small>
                                    </ProfileHeading>
                                </div>
                            </div>
                            <div className='d-flex flex-column text-center'>
                                <small className='fs-6 text-secondary'> FEEDBACK</small>
                                {profileData[0]?.meanpercentage ? `${profileData[0]?.meanpercentage}%` : '-'}
                            </div>
                        </div>

                    </CardContainer>

                    <Card className="sc-jIGnZt hqSwak card col-12 my-3 p-3 d-flex flex-row justify-content-between">
                        <div className='d-flex gap-2 flex-column pt-3'>
                            <Label1>Total Trading</Label1>
                            <Para1 className='m-0'>{userTradeProfile?.totalTrade}</Para1>
                        </div>
                        <div className='d-flex gap-2 flex-column pt-3'>
                            <Label>30 Days Trade </Label>
                            <Para className='m-0'>{userTradeProfile?.get30daysTrade}</Para>
                        </div>
                        <div className='d-flex gap-2 flex-column pt-3'>
                            <Label>Avg Release Time</Label>
                            <Para className='m-0'>{userTradeProfile?.AverageofReleasetime} minutes</Para>
                        </div>
                        <div className='d-flex gap-2 flex-column pt-3'>
                            <Label>Avg Pay Time</Label>
                            <Para className='m-0'>{userTradeProfile?.AverageofPaidtime} minutes</Para>
                        </div>
                    </Card>

                    <div className='col-12 col-xl-4 p-xl-3 d-flex d-xl-none'>
                        <ProfileDetails profileData={profileData} />
                    </div>

                </div>
                <div className='col-md-4 p-3 d-none d-xl-block'>
                    <ProfileDetails profileData={profileData} />
                </div>
            </div>
            <Div>
                <Headings>Active Buy Offers</Headings>
                <DataTable
                    theme='solarized'
                    columns={columns}
                    data={buyOffer}
                    customStyles={customStyles}
                    pagination
                    showPageSizeOptions={true}
                    paginationRowsPerPageOptions={[5, 10, 20, 25, 50, 100]}
                    paginationResetDefaultPage={true}
                    paginationPerPage={4}
                />
            </Div>
            <Div>
                <Headings>Active Sell Offers</Headings>
                <DataTable
                    theme='solarized'
                    columns={columns}
                    customStyles={customStyles}
                    data={sellOffer}
                    pagination
                    showPageSizeOptions={true}
                    paginationRowsPerPageOptions={[5, 10, 20, 25, 50, 100]}
                    paginationResetDefaultPage={true}
                    paginationPerPage={4}
                />
            </Div>
            <div className='mt-2'>
                <Div>
                    <Headings>Feedbacks</Headings>
                    <DataTable
                        theme='solarized'
                        columns={feedColumn}
                        customStyles={customStyles}
                        data={feedData}
                        pagination
                        showPageSizeOptions={true}
                        paginationRowsPerPageOptions={[5, 10, 20, 25, 50, 100]}
                        paginationResetDefaultPage={true}   
                        paginationPerPage={4}
                    />
                </Div>
            </div>
        </Container>
    )
}