import React, { useEffect, useRef, useState } from 'react'
import { useAuthContextHook } from "../../../Context/AuthContext";
import OTPInput from "otp-input-react";
import '../AuthCss/Login.css'
import styled from 'styled-components';
import ButtonLoader from '../../../Component/Loader/ButtonLoader';
import { BsArrowLeftShort } from 'react-icons/bs'
import PropTypes from 'prop-types';

const Button = styled.button`
border: none;
background: transparent;
`
export const Para = styled.p`
font-size: 0.9rem;
`

const ParaDiv = styled.div`
font-size: 0.9rem;
`

const ResizePara = styled.p`
&:active {
    transform: scale(0.9);
}
`

export default function Otp({ setStep, step }) {
    const { handleOtpVerification, handleSignUp, cred } = useAuthContextHook();
    const [loader, setLoader] = useState(false)
    const [otpLoader, setOtpLoader] = useState(false)
    const [otpValue, setOtpValue] = useState('');
    const [time, setTime] = useState(600);
    
    useEffect(() => {
      const interval = setInterval(() => {
        if (time > 0) {
          setTime(time - 1);
        } else {
          clearInterval(interval);
        }
      }, 1000);

      return () => clearInterval(interval);
    }, [time]);
  
    const formatTime = (seconds) => {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoader(true)
            await handleOtpVerification(otpValue);
            setLoader(false)
        } catch (error) {
            setLoader(false)
        }
    }

    const handleResetOtp = async (e) => {
        e.preventDefault();
        if (time === 0) {
            try {
                setOtpLoader(true)
                await handleSignUp(cred.email, cred.password, cred.referralCode);
                setTime(600);
                setOtpLoader(false)
            } catch (error) {
                setOtpLoader(false)
            }
        }
    }    
    return (
        <div className='mb-5'>
            <div>
                <Button onClick={(e) => {
                    e.preventDefault();
                    setStep(step - 1)
                }}>
                    <BsArrowLeftShort size={25} color='black' />
                </Button>
            </div>
            <div className='mb-1'>
                <p className='fw-bold pt-3 fs-3 mb-0'>Create an Account</p>
                <Para className='text-center' >We have sent a verification code to your email.</Para>
            </div>
            <div className='card-body p-0 mt-2'>
                <form>
                    <div className='FormDiv d-flex m-0  flex-column'>
                        <OTPInput
                            className='OtpInputBox'
                            value={otpValue}
                            onChange={setOtpValue}
                            autoFocus
                            OTPLength={4}
                            otpType="number"
                            disabled={false}
                            secure />
                    </div>
                    {time === 0 ?
                        <Para className='text-center pt-3'>Verification Code is expired</Para> :
                        <Para className='text-center pt-3'>Verification Code will expire in {formatTime(time)} minutes</Para>
                    }
                    <ParaDiv className='text-center text-secondary py-1  m-0 pt-0 mt-0'>Didn’t Get Verification Code ?<ResizePara
                        className='text-primary'
                        disabled={time !== 0}
                        onClick={handleResetOtp}>
                        {
                            otpLoader ? 'Sending Verification Code..' : 'Resend Verification Code'
                        }</ResizePara>
                    </ParaDiv>
                    <div className='d-flex justify-content-center mt-0'>
                        <button disabled={loader || otpValue.length < 4} className='Button mt-0 d-flex justify-content-center' onClick={onSubmit} type="submit">
                            {
                                loader ? <> <ButtonLoader /> <span>checking..</span></> : 'Submit'
                            }
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}
Otp.propTypes = {
    step: PropTypes.number.isRequired,
    setStep: PropTypes.func.isRequired,
};