import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { API_URLs } from '../Utils/ApiUrls';
// for cookies
import { useCookies } from 'react-cookie';

export const ThemeContextVar = createContext();
export const useThemeContext = () => {
    return useContext(ThemeContextVar);
}

// function useLocalStorageState(key, defaultValue) {
//     const [value, setValue] = useState(() => {
//     const storedValue = localStorage.getItem(key);
//     return storedValue ? JSON.parse(storedValue) : defaultValue;
//     });
    
//     useEffect(() => {
//         localStorage.setItem(key, JSON.stringify(value));
//     }, [key, value]);
    
//     return [value, setValue];
//     }
export default function ThemeContext({ children }) {
    const [cookies, setCookie] = useCookies(['user']);

    const [theme, setTheme] = useState("light");
    const [direction, setDirection] = useState('ltr');
    const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
    const [sidebarToggled, setSidebarToggled] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const [country, setCountry] = useState(null);
    const [ipAddress, setIPAddress] = useState('');
    const [blockedCountryList, setBlockedCountryList] = useState([])
    const [navbarHeading, setNavbarHeading] = useState("")
    const handleSetNavbarHeading = (heading) => {
        setNavbarHeading(heading)
    }

    const handleSetSidebarCollapse = () => setSidebarCollapsed(!sidebarCollapsed)
    const handleSetSidebarToggle = () => setSidebarToggled(!sidebarToggled)

    const isDarkTheme = theme === "dark";
    const toggleTheme = () => {
        setTheme(isDarkTheme ? "light" : "dark");
    }

    const handleChangeDirection = () => {
        if (direction === 'rtl') {
            setDirection('ltr')
        }
        else {
            setDirection('rtl');
        }
    }

    const handleResize = () => {
        setScreenWidth(window.innerWidth)
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [])

    const getYourCountry = () => {
        fetch('https://api.ipify.org?format=json')  
            .then((response) => response.json())
            .then((data) => {
                const userIP = data.ip;
                setIPAddress(userIP);
                setCookie('browserIP', userIP, { path: '/' });
                fetch(`https://ipapi.co/${userIP}/country/`)
                .then((response) => response.text())
                .then((country) => {
                        setCountry(country)
                        setCookie('country', country, { path: '/' });
                        checkBlockStatus();
                    })
                    .catch((error) => console.error('Error fetching country:', error));
            })
            .catch((error) => console.error('Error fetching IP:', error));
    }

    const checkBlockStatus = () => {
        axios.get(API_URLs.getBlockCountry)
            .then((res) => {
                setBlockedCountryList(res.data)
            })
            .catch((error) => {
                
            })
    }
    

    const exportableData = {
        toggleTheme,
        handleChangeDirection,
        isDarkTheme,
        direction,
        sidebarCollapsed,
        sidebarToggled,
        handleSetSidebarCollapse,
        handleSetSidebarToggle,
        screenWidth,
        navbarHeading,
        handleSetNavbarHeading,
        country,
        blockedCountryList,
        getYourCountry,
        ipAddress,
    }

    return (
        <ThemeContextVar.Provider
            value={{ ...exportableData }}
        >
            {children}
        </ThemeContextVar.Provider>
    )
}

export const ThemeConsumer = ThemeContextVar.Consumer;