import React, { useEffect, useState } from 'react'
import { useAuthContextHook } from '../../Context/AuthContext';
import { BsDot } from 'react-icons/bs';
import { ProfileHeading } from '../Profile/StyledComponents';
import { useNavigate } from 'react-router-dom';
import { TiTick } from 'react-icons/ti'
import styled from 'styled-components';
import { useThemeContext } from '../../Context/ThemeContext';
import { v4 as uuidv4 } from 'uuid';

const Tick = styled.span`
border-radius: 50%;
background: #1476FF;
padding: 2px;
border: 5px solid aliceblue;
`
const Tick1 = styled.span`
border-radius: 50%;
padding: 2px;
font-size: 12px;
color: transparent;
background: aliceblue;
border: 5px solid aliceblue;
`
const Div = styled.div`
width:75%;
min-height: 12rem;
box-shadow: ${({theme})=> theme.boxshadow};
border: ${({theme})=> theme.border};
background: transparent;
`
const Div1 = styled.div`
background: ${({theme})=>theme.body==='#F7F9FC' ? 'white':theme.body};
box-shadow: ${({theme})=>theme.boxshadow};
border: ${({theme})=>theme.border};
`
const Line = styled.div`
height: 40px;
border: 2px solid #4541414a;
margin-left: 20px;
width: 1px;
`
const Button = styled.button`
border: none;
outline: none;
box-shadow: 0px 0px 10px #0257FB30;
background: #1476FF;
border-radius: 20px;
color: white;
padding:10px 15px;
`
const content = [
    {
        num: 1,
        level: 'Level 1',
        name: 'Phone & Email Verified ',
        benefitsList: [
            'Trade up to $1000 per trade',
            'Account limit: $10,000',
            'Convert one crypto into another',
            'Send and receive crypto'
        ]
    },
    {
        num: 2,
        level: 'Level 2',
        name: 'ID Verification',
        benefitsList: [
            'Trade up to $10,000 per trade',
            'Account limit: unlimited'
        ]
    },
    {
        num: 3,
        level: 'Level 3',
        name: 'Address Verification',
        benefitsList: [
            'Trade up to $50,000 per trade',
            'Account limit: unlimited'
        ]
    },
    {
        num: 4,
        level: 'Level 4',
        name: 'Enhance due deligency',
        benefitsList: [
            'Trade up to $1,00,000 per trade',
            'Account limit: unlimited'
        ]
    }
]
export default function Kyclevel() {
    const { userDetails } = useAuthContextHook();
    const {isDarkTheme} = useThemeContext();
    const [level, setLevel] = useState()
    const navigate = useNavigate()
    useEffect(() => {
        setLevel(Number(userDetails?.user?.kycLevel?.split('Level ')[1]))
    }, [userDetails, navigate])
    return (
        <div className='row m-0'>
            <Div1 className='col-md-9 card m-3 p-4'>
                <ProfileHeading >KYC Account Limits</ProfileHeading>
                <hr className='my-2'></hr>
                {
                    content.map((data) => {
                        return (
                            <div key={uuidv4()}>
                                <Line className={level && data.num<=level && `${isDarkTheme ? 'border border-light':'border border-primary'}`}></Line>
                                <div className='row m-0'>
                                    <Div className={`${level && data.num<=level && `${isDarkTheme ? 'border border-light':'border border-primary'}`} col-md-5 col-sm-12 card d-flex flex-row gap-2 p-3`}>
                                        {level && <div>
                                            {
                                                data.num <= level ?
                                                <Tick>
                                                    <TiTick size={22} color='white' />
                                                </Tick> :
                                                <Tick1>eif</Tick1>
                                            }
                                        </div>}
                                        <div className='w-100 d-flex justify-content-between'>
                                        <div>
                                            <div className='d-flex gap-2 align-items-center flex-wrap'>
                                            <h5 className='fw-bold'>{data.level}</h5>
                                            <h5 className='fs-6' style={{color:"#6F7182"}}>{data.name}</h5>
                                            </div>
                                            {
                                                data.benefitsList.map((list ) => {
                                                    return (
                                                        <span key={uuidv4()} className='d-flex align-items-center'>
                                                            <BsDot />
                                                            <span>{list}</span>
                                                        </span>
                                                    );
                                                })
                                            }
                                        </div>
                                        <div>
                                            {
                                                level && <div>
                                                    {
                                                        data.num <= level ? "" :
                                                            <Button disabled={data.num != level+1} onClick={() => navigate('/dashboard/kyc')}>Verify</Button>
                                                    }
                                                </div>
                                            }

                                        </div>
                                        </div>
                                    </Div>
                                </div>
                            </div>
                        );
                    })
                }
            </Div1>
        </div>
    );
}