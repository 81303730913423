const environment = {
    development: {
        BASE_URL: process.env.REACT_APP_BASE_URL,
        NEW_BASE_URL: process.env.REACT_APP_NEW_BASE_URL,
        NOTIFICATION_BASE_URL: process.env.REACT_APP_NOTIFICATION_BASE_URL,
        OFFER_BASE_URL: process.env.REACT_APP_OFFER_BASE_URL,
    },
    staging: {
        BASE_URL: process.env.REACT_APP_STAGE_BASE_URL,
        NEW_BASE_URL: process.env.REACT_APP_STAGE_NEW_BASE_URL,
        NOTIFICATION_BASE_URL: process.env.REACT_APP_STAGE_NOTIFICATION_BASE_URL,
        OFFER_BASE_URL: process.env.REACT_APP_STAGE_OFFER_BASE_URL,
    },
    production: {
        BASE_URL: process.env.REACT_APP_PROD_BASE_URL,
        NEW_BASE_URL: process.env.REACT_APP_PROD_NEW_BASE_URL,
        NOTIFICATION_BASE_URL: process.env.REACT_APP_PROD_NOTIFICATION_BASE_URL,
        OFFER_BASE_URL: process.env.REACT_APP_PROD_OFFER_BASE_URL,
    },
}
export const my_API_URL = environment[process.env.REACT_APP_API_ENVIROMENT];


export const API_URLs = {
    //signup 
    signUp: `${my_API_URL.BASE_URL}/user-service-api/v1/auth/register`,
    signUpOtp: `${my_API_URL.BASE_URL}/user-service-api/v1/auth/verify-email-token`,
    signUpPassword: `${my_API_URL.BASE_URL}/user-service-api/v1/auth/verify-otp-token`,
    signUpMobile: `${my_API_URL.BASE_URL}/user-service-api/v1/auth/register/send-otp-verifyPhone`,
    signUpMobileVerify: `${my_API_URL.BASE_URL}/user-service-api/v1/auth/register/verifyPhone-otp`,
    signUpNickName: `${my_API_URL.BASE_URL}/user-service-api/v1/auth/save-nickname`,
    // login
    logIn: `${my_API_URL.BASE_URL}/user-service-api/v1/auth/login`,
    sendMobileOTP: `${my_API_URL.BASE_URL}/user-service-api/v1/auth/send-otp-verifyPhone`,
    verifyMobileOTP: `${my_API_URL.BASE_URL}/user-service-api/v1/auth/verifyPhone-otp`,
    verifyEmail: `${my_API_URL.BASE_URL}/user-service-api/v1/auth/send-otp-verifyemail`,
    verifyEmailOTP: `${my_API_URL.BASE_URL}/user-service-api/v1/auth/verifyemail-otp`,
    loginVerify: `${my_API_URL.BASE_URL}/user-service-api/v1/auth/verify-otp`,
    
    // forgot
    forgot: `${my_API_URL.BASE_URL}/user-service-api/v1/auth/forgot-password`,

    // reset
    reset: `${my_API_URL.BASE_URL}/user-service-api/v1/auth/reset-password`,

    // get user
    getUser: `${my_API_URL.BASE_URL}/user-service-api/v1/auth/profile`,

    // update profile
    profile2Fa: `${my_API_URL.BASE_URL}/user-service-api/v1/auth/profile2FA`,
    profileUpdate: `${my_API_URL.BASE_URL}/user-service-api/v1/auth/profile`,
    verify2faTxn: `${my_API_URL.BASE_URL}/user-service-api/v1/auth/request-2fa`,
    changePassword: `${my_API_URL.BASE_URL}/user-service-api/v1/auth/change-password`,
    kyc: `${my_API_URL.BASE_URL}/user-service-api/v1/auth/kyc`,
    logout: `${my_API_URL.BASE_URL}/user-service-api/v1/auth/logout`,
    twoFa: `${my_API_URL.BASE_URL}/user-service-api/v1/auth/toggle-twofa`,
    verifyTwoFaToggle: `${my_API_URL.BASE_URL}/user-service-api/v1/auth/verify-token-twofa`,
    verifyTwoFa: `${my_API_URL.BASE_URL}/user-service-api/v1/auth/verify-twofa`,
    fiatTransaction: `${my_API_URL.BASE_URL}/user-service-api/v1/transaction/`,
    fiatWallet: `${my_API_URL.BASE_URL}/user-service-api/v1/transaction/wallet/balance`,
    cryptoBalance: `${my_API_URL.BASE_URL}/user-service-api/v1/wallet/get-blanace`,
    cryptoConvert: `${my_API_URL.BASE_URL}/user-service-api/v1/converter/crypto/to/crypto`,
    getWallet: `${my_API_URL.BASE_URL}/user-service-api/v1/wallet/get-wallets`,
    getSingleWallet: (crypto) => {
        return `${my_API_URL.NEW_BASE_URL}/wallet-service/v1/usercrypto/getWallet/${crypto}`
    },
    listCryptoWallets: `${my_API_URL.NEW_BASE_URL}/wallet-service/v1/usercrypto/getWallets`, 
    addFiatTransaction: `${my_API_URL.NEW_BASE_URL}/wallet-service/v1/userwallet/send-fiat`,
    listFiatTransactions: `${my_API_URL.NEW_BASE_URL}/wallet-service/v1/userwallet/fiat/transactions`,
    listCryptoTransactions: `${my_API_URL.NEW_BASE_URL}/wallet-service/v1/usercrypto/transactions`, 
    listFiatWallet: `${my_API_URL.NEW_BASE_URL}/wallet-service/v1/userwallet/fiat/wallets`,
    getEstimation: (currency, amount, reciever, type) => {
        return `${my_API_URL.NEW_BASE_URL}/wallet-service/v1/conversion/getEstimateGasfee/${currency}/${amount}/${reciever}/${type}`
    },
    sendETH: `${my_API_URL.NEW_BASE_URL}/wallet-service/v1/usercrypto/send/eth`, 
    sendBTC: `${my_API_URL.NEW_BASE_URL}/wallet-service/v1/usercrypto/send/btc`, 
    sendUSDC: `${my_API_URL.NEW_BASE_URL}/wallet-service/v1/usercrypto/send/usdc`, 
    sendUSDT: `${my_API_URL.NEW_BASE_URL}/wallet-service/v1/usercrypto/send/usdt`, 
    sendMATIC: `${my_API_URL.NEW_BASE_URL}/wallet-service/v1/usercrypto/send/matic`, 
    getInBTC: `${my_API_URL.NEW_BASE_URL}/wallet-service/v1/getCryptosInBtc`,
    createOffer: `${my_API_URL.OFFER_BASE_URL}/offer-service-api/v1/offers/offer`, 
    getMinTradeLimit: (id) => `${my_API_URL.OFFER_BASE_URL}/offer-service-api/v1/offers/minOfferAmountsForUser/${id}`,
    getOfferForCurrency: (currency, payment_method, spend_amount, preffered_currency, offer_location, offer_owner_loction, lastTenMinActive, trade_offer_type_deal) => {
        return `${my_API_URL.OFFER_BASE_URL}/offer-service-api/v1/offers/offer/filter?cryptoCurrency=${currency}${payment_method ? `&payment_method=${payment_method}` : ""}${spend_amount ? `&spend_amount=${spend_amount}` : ""}${preffered_currency ? `&preffered_currency=${preffered_currency}` : ""}${offer_location ? `&offer_location=${offer_location}` : ""}${offer_owner_loction ? `&offer_owner_loction=${offer_owner_loction}` : ""}${lastTenMinActive ? `&lastTenMinActive=${lastTenMinActive}` : ""}${trade_offer_type_deal ? `&trade_offer_type_deal=${trade_offer_type_deal}` : ""}`
    },
    getOfferDetailsById: (offerID) => {
        return `${my_API_URL.OFFER_BASE_URL}/offer-service-api/v1/buyoffer/offer/list/${offerID}`
    },
    getBuyOffers: `${my_API_URL.OFFER_BASE_URL}/offer-service-api/v1/buyoffer/offer/list`,
    viewalloffers: `${my_API_URL.OFFER_BASE_URL}/offer-service-api/v1/offers/offer`,
    initiateTrade: (tradeID) => {
        return `${my_API_URL.OFFER_BASE_URL}/offer-service-api/v1/buyoffer/offer/request/v1/${tradeID}`
    },
    getTransactionDetails: (tradeID, txnHash) => {
        return `${my_API_URL.OFFER_BASE_URL}/offer-service-api/v1/buyoffer/offer/request/v1/${tradeID}/${txnHash}/view`
    },
    cancelTransaction: (tradeID, txnHash, reason) => {
        return `${my_API_URL.OFFER_BASE_URL}/offer-service-api/v1/buyoffer/offer/request/v1/${tradeID}/${txnHash}/${reason}/cancel`
    },
    offerGraph: (id)=> {
        return `${my_API_URL.OFFER_BASE_URL}/offer-service-api/v1/trustedUser/monthlyOffer/${id}`
    },
    btcGraph: (id)=> {
        return `${my_API_URL.NEW_BASE_URL}/wallet-service/v1/conversion/getmonthlyData/${id}`
    },
    dispute: `${my_API_URL.NEW_BASE_URL}/wallet-service/v1/userdispute/`,
    tradeDispute: `${my_API_URL.OFFER_BASE_URL}/offer-service-api/v1/tradeDispute`,
    getDisputeCatogory: `${my_API_URL.OFFER_BASE_URL}/offer-service-api/v1/disputeCategories/getDisputeCategories`,
    sendFeedback: `${my_API_URL.OFFER_BASE_URL}/offer-service-api/v1/userFeedback/`,
    getAllCountry: `${my_API_URL.BASE_URL}/user-service-api/v1/public-users/all-country`,
    getFiatCurrencies: `${my_API_URL.BASE_URL}/user-service-api/v1/public-users/all-fiat/currency`,
    getCryptoCurrencies: `${my_API_URL.BASE_URL}/user-service-api/v1/public-users/all-currency`,
    getAllStatesByCountry: (countryID) => {
        return `${my_API_URL.BASE_URL}/user-service-api/v1/public-users/get-state/${countryID}`
    },
    getAllCitiesByState: (countryID, stateCode) => {
        return `${my_API_URL.BASE_URL}/user-service-api/v1/public-users/get-city/${countryID}/${stateCode}`
    },
    sendChat: `${my_API_URL.OFFER_BASE_URL}/offer-service-api/v1/userchat/add-chat`,
    getChat: `${my_API_URL.OFFER_BASE_URL}/offer-service-api/v1/userchat/get-chat`,
    addProof: `${my_API_URL.OFFER_BASE_URL}/offer-service-api/v1/userchat/add-proof`,
    cryptoToFiat: `${my_API_URL.NEW_BASE_URL}/wallet-service/v1/conversion`,
    convertCryptoToFiat: (from, amount, to) => {
        return `${my_API_URL.NEW_BASE_URL}/wallet-service/v1/conversion?from=${from}&amount=${amount}&target=${to}`
    },

    // swapCryptoCurrency: (from, to, amount) => {
    //     return `${my_API_URL.NEW_BASE_URL}/wallet-service/v1/convertCurrency/swapCuurencies?from=${from}&amount=${amount}&target=${to}`
    // },
    swapCryptoCurrency:`${my_API_URL.NEW_BASE_URL}/wallet-service/v1/convertCurrency/swapCuurencies`,

    convertFiatToCrypto: (from, to, amount) => {
        return `${my_API_URL.NEW_BASE_URL}/wallet-service/v1/fiatToCrypto/convertFiatToCrypto?from=${from}&amount=${amount}&target=${to}`
    },
    conversionWithFees: (from, to, amount, offerId) => {
        return `${my_API_URL.NEW_BASE_URL}/wallet-service/v1/conversion/conversionWithFees/${offerId}?from=${from}&amount=${amount}&target=${to}`
    },
    postUserActivity: `${my_API_URL.BASE_URL}/user-service-api/v1/activity`,
    publicprofile: `${my_API_URL.OFFER_BASE_URL}/offer-service-api/v1/publicapi/userOffer`,
    // publicUserProfile: `${my_API_URL.OFFER_BASE_URL}/offer-service-api/v1/publicapi/userPublicProfile`,
    // http://18.218.30.200:4003/offer-service-api/v1/publicapi/userPublicTradeProfile/:id
    getPublicUserTradeProfile: `${my_API_URL.OFFER_BASE_URL}/offer-service-api/v1/publicapi/userPublicTradeProfile`,

   
    getFeedback: (id) => {
        return `${my_API_URL.OFFER_BASE_URL}/offer-service-api/v1/offers/getFeedback/${id}`
    },
    activetrade: `${my_API_URL.OFFER_BASE_URL}/offer-service-api/v1/tradeUserOffer/`,
    FcmToken: `${my_API_URL.NOTIFICATION_BASE_URL}/notification-service-api/v1/fcmToken/fcmDeviceToken`,
    getNotifications: `${my_API_URL.NOTIFICATION_BASE_URL}/notification-service-api/v1/fcmToken/getNotifications`,
    readNotification: (notificationId) => {
        return `${my_API_URL.NOTIFICATION_BASE_URL}/notification-service-api/v1/fcmToken/readNotification/${notificationId}`
    },
    readAllNotifications: `${my_API_URL.NOTIFICATION_BASE_URL}/notification-service-api/v1/fcmToken/readAllNotifications`,
    sendNotification: (notificationID) => {
        return `${my_API_URL.NOTIFICATION_BASE_URL}/notification-service-api/v1/fcmToken/sendNotification/${notificationID}`
    },
    deleteNoticationFCM: `${my_API_URL.NOTIFICATION_BASE_URL}/notification-service-api/v1/fcmToken/fcmDeviceToken`,
    iHavePaid: (offerId, txnHash) => {
        return `${my_API_URL.OFFER_BASE_URL}/offer-service-api/v1/buyoffer/offer/request/v1/${offerId}/${txnHash}/ihavepaid`
    },
    releasePayment: (offerId, txnHash, otpHash, otpValue) => {
        return `${my_API_URL.OFFER_BASE_URL}/offer-service-api/v1/buyoffer/offer/request/v1/${offerId}/${txnHash}/release-payment?token=${otpHash}&otp=${otpValue}`
    },
    allBanks: `${my_API_URL.BASE_URL}/user-service-api/v1/public-users/all-bank`,
    userNameCheck: `${my_API_URL.BASE_URL}/user-service-api/v1/auth/check-nicknames`,
    bankPost: `${my_API_URL.BASE_URL}/user-service-api/v1/auth/bank`,
    getBank: `${my_API_URL.BASE_URL}/user-service-api/v1/auth/bank`,
    deleteBank: (id) => `${my_API_URL.BASE_URL}/user-service-api/v1/auth/bank/${id}`,
    card: `${my_API_URL.BASE_URL}/user-service-api/v1/auth/cards`,
    getCard: `${my_API_URL.BASE_URL}/user-service-api/v1/auth/cards`,
    deleteCard: (id) => `${my_API_URL.BASE_URL}/user-service-api/v1/auth/card/${id}`,
    getCardById: (id) => `${my_API_URL.BASE_URL}/user-service-api/v1/auth/card/${id}`,
    updateCard: (id) => `${my_API_URL.BASE_URL}/user-service-api/v1/auth/card/${id}`,
    upi: `${my_API_URL.BASE_URL}/user-service-api/v1/auth/UPI`,
    getUpi: `${my_API_URL.BASE_URL}/user-service-api/v1/auth/UPI`,
    getUpiById: (id) => `${my_API_URL.BASE_URL}/user-service-api/v1/auth/UPI/${id}`,
    deleteUpi: (id) => `${my_API_URL.BASE_URL}/user-service-api/v1/auth/UPI/${id}`,
    updateUpi: (id) => `${my_API_URL.BASE_URL}/user-service-api/v1/auth/UPI/${id}`,
    paypal: `${my_API_URL.BASE_URL}/user-service-api/v1/auth/paypal`,
    deletePayPal:(id) => `${my_API_URL.BASE_URL}/user-service-api/v1/auth/paypal/${id}`,
    getAccessToken: `${my_API_URL.BASE_URL}/user-service-api/v1/sumsub/getAccessTokenAPI`,
    sendWithdrawal: `${my_API_URL.NEW_BASE_URL}/wallet-service/v1/withdrawl/withdrawlAmount`,
    getBlockCountry: `${my_API_URL.BASE_URL}/user-service-api/v1/admin/BlockCountriesForUser`,
    paymentMethodList: `${my_API_URL.OFFER_BASE_URL}/offer-service-api/v1/offers/paymentMethods`,
    phoneCodes: `${my_API_URL.BASE_URL}/user-service-api/v1/admin/countryPhoneCodeForDashboard`,
    updateCurrency: `${my_API_URL.BASE_URL}/user-service-api/v1/auth/update-currency`,
    createInvoice: `${my_API_URL.NEW_BASE_URL}/wallet-service/v1/lightiningNetwork/receiveBTCByLighning`,
    decodeInvoice:(invoice)=> `${my_API_URL.NEW_BASE_URL}/wallet-service/v1/lightiningNetwork/decodeInvoice?invoice=${invoice}`,
    sendBTCByLighning: `${my_API_URL.NEW_BASE_URL}/wallet-service/v1/lightiningNetwork/sendBTCByLighning`,
    getLightningTxn: `${my_API_URL.NEW_BASE_URL}/wallet-service/v1/lightiningNetwork/invoiceUserList`,
    getInvoiceStatus: (id)=>`${my_API_URL.NEW_BASE_URL}/wallet-service/v1/lightiningNetwork/checkInvoiceStatus/${id}`,
    getBalanceLightning: `${my_API_URL.NEW_BASE_URL}/wallet-service/v1/lightiningNetwork/getUserLightningBalance`,
    getswapConversion:`${my_API_URL.NEW_BASE_URL}/wallet-service/v1/convertCurrency/getswapConversion`,

    //changely
    getChangelyCurrency: `${my_API_URL.NEW_BASE_URL}/wallet-service/v1/changelly/getCurrencys`,
    getChangelyFixAmount: (from, to, amount) => `${my_API_URL.NEW_BASE_URL}/wallet-service/v1/changelly/fixAmountExhnageRate/${from}/${to}/${amount}`,
    getChangelyCreateTxn: `${my_API_URL.NEW_BASE_URL}/wallet-service/v1/changelly/createTransactionFixRate`,

    //refer 
    getAllReferTxn : `${my_API_URL.OFFER_BASE_URL}/offer-service-api/v1/reward/myUnClaimRewardDetails`,
    clainAll: (option)=> `${my_API_URL.OFFER_BASE_URL}/offer-service-api/v1/reward/claimRequest/${option}`,
    getTop5Refer: `${my_API_URL.OFFER_BASE_URL}/offer-service-api/v1/reward/largestAmount`,
    myReferalUser: `${my_API_URL.OFFER_BASE_URL}/offer-service-api/v1/reward/getMyReferralUser`,
    notificationSetting: `${my_API_URL.BASE_URL}/user-service-api/v1/setting/notification`,
    subNotificationUpdate: `${my_API_URL.BASE_URL}/user-service-api/v1/setting/notificationOption`,
    userAct: `${my_API_URL.BASE_URL}/user-service-api/v1/activity/login-history`,
}