import React, { useState, useRef, useEffect } from 'react'
import './Profile.css'
import { useNavigate } from 'react-router-dom'
import { Heading, BlueButton, ProfileImg, ProfileHeading, WhiteButton, VerifyButton, InputContainer, InputLabel, Input, Input1, BlueButton1 } from './StyledComponent'
import styled from 'styled-components'
import { Oval } from 'react-loader-spinner'
import Modal from 'react-bootstrap/Modal';
import { useFormik } from 'formik'
import OTPInput from "otp-input-react";
import * as Yup from 'yup'
import { RxCrossCircled } from 'react-icons/rx'
import { useAPIContextHook } from '../../Context/ApiContext'
import { useAuthContextHook } from '../../Context/AuthContext'
import Select from 'react-select'
import { darkTheme, lightTheme } from '../../Theme/theme';
import { useThemeContext } from '../../Context/ThemeContext'
import CustomModal from '../../hooks/CustomModal';
import axios from 'axios'
import { API_URLs } from '../../Utils/ApiUrls'
import { MdEdit, MdOutlineDeleteOutline } from 'react-icons/md';
import { Para } from '../Auth/SignUp/Otp'
import useDetectFormChanges from './useDetectFormChanges'

const ChangeText = styled.span`
font-size: 12px;
font-weight: 600;
color: #1476FF;
`
const Div = styled.div`
border: ${({ theme }) => theme.border};
box-shadow: ${({ theme }) => theme.boxshadow};
background: ${({ theme }) => theme.body == '#F7F9FC' ? 'white' : theme.body};
`
// const initialValues = {
//     name: '', email: '', phone: '', kyc: '', profileImage: '', documentImage: '', addressLine1: '', addressLine2: '', postcode: '', phonecode: '', city: '', state: '', country: '', currency: '', bio: '', nickname: ''
// }

export default function Profile() {
    const [isEditable, setIsEditable] = useState(false)
    const [loading, setLoading] = useState(false)
    const { userDetails } = useAuthContextHook()
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [profileImageBlob, setProfileImageBlob] = useState("");
    const [profileImageElement, setProfileImageElement] = useState();
    const [otpValue, setOtpValue] = useState('')
    const { countryOptions, customFilterForCountry, fiatCurrencyOptions, fetchStates, fetchCities } = useAPIContextHook()
    const { handleSetNavbarHeading, isDarkTheme } = useThemeContext()
    const { getUserDetails } = useAuthContextHook();
    const [changeMobile, setChangeMobile] = useState(false);
    const [changeEmail, setChangeEmail] = useState(false);
    const [isSearching, setIsSearching] = useState(false);

    const { phoneCode } = useAPIContextHook();
    const [initialData, setInitialData] = useState({
        name: '',
        email: '',
        phone: '',
        kyc: '',
        profileImage: '',
        documentImage: '',
        addressLine1: '',
        addressLine2: '',
        postcode: '',
        phonecode: '',
        city: '',
        state: '',
        country: '',
        currency: '',
        bio: '',
        nickname: ''
    });

    const profileImageRef = useRef();
    const navigate = useNavigate();
    // modal states
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);
    const handleShowModal = (data) => {
        setModalData(data);
        setShowModal(true);
    };
    const handleCloseModal = () => {
        setOtpValue('');
        setShowModal(false)
    };


    //otp modal
    const [isProfileDoneOTP, setIsProfileDoneOTP] = useState(false);
    const handleOpenOTP = () => { setIsProfileDoneOTP(true) }
    const handleCloseOTP = () => {
        setOtpValue('');
        setIsProfileDoneOTP(false)
    }
    const [otpData, setOtpData] = useState()

    // mobile otp time
    const [mobileOTP, setMobileOTP] = useState(false);
    const handleOpenMobileOTP = () => setMobileOTP(true);
    const handleCloseMobileOTP = () => {
        setOtpValue('');
        setMobileOTP(false)
    }
    // email otp time
    const [emailOTP, setEmailOTP] = useState(false);
    const handleOpenEmailOTP = () => setEmailOTP(true);
    const handleCloseEmailOTP = () => {
        setOtpValue('');
        setEmailOTP(false)
    }
    const handleCheckMobileOTP = (otp) => {
        axios.post(API_URLs.verifyMobileOTP, {
            token: localStorage.getItem('mobile'),
            otp: otp
        }, {
            headers: {
                accept: 'application/json',
                Authorization: `Bearer ${localStorage.getItem('jwt')}`
            }
        })
            .then((res) => {
                setChangeMobile(false)
                handleCloseMobileOTP()
                getUserDetails();
                handleShowModal(res?.data?.msg ? res?.data?.msg : "Mobile Verified!!");
                formik.setFieldValue(formik.values.phone);

            })
            .catch((error) => {
                setChangeMobile(false)
            })
    }
    const handleCheckEmailOTP = (otp) => {
        axios.post(API_URLs.verifyEmailOTP, {
            token: localStorage.getItem('emailverification'),
            otp: otp
        }, {
            headers: {
                accept: 'application/json',
                Authorization: `Bearer ${localStorage.getItem('jwt')}`
            }
        })
            .then((res) => {
                setChangeEmail(false);
                handleCloseEmailOTP()
                handleShowModal(res?.data?.msg ? res?.data?.msg : "Email Address Verified!!");
                getUserDetails();
            })
            .catch((error) => {
                setChangeEmail(false);
            })
    }
    const SelectStyles = {
        control: (styles) => ({
            ...styles,
            background: isDarkTheme ? darkTheme.body : lightTheme.inputBG,
            borderRadius: '52px',
            width: '15rem',
            boxShadow: `${isDarkTheme ? darkTheme.boxshadow : lightTheme.boxshadow}`,
            border: `${isDarkTheme ? darkTheme.border : lightTheme.border}`, "&:hover": {
                border: `${isDarkTheme ? darkTheme.border : lightTheme.border}`
            },
            color: isDarkTheme ? darkTheme.text : lightTheme.text
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: isDarkTheme && state.isHovered ? '#302F30' : `${({ theme }) => theme.body}`,
            ':hover': {
                backgroundColor: `${isDarkTheme ? '#302F30' : '#D9EDE7'}`,
            },
            color: isDarkTheme && state.isSelected ? '#1476FF' : `${({ theme }) => theme.text}`,
        }),
        indicatorSeparator: () => ({
            all: 'unset'
        }),
        indicatorsContainer: (styles) => ({
            ...styles, color: isDarkTheme ? darkTheme.text : lightTheme.text, 'div:nth-child(2)': {
                color: isDarkTheme ? darkTheme.text : lightTheme.text
            }
        }),
        menu: (styles) => ({
            ...styles, background: isDarkTheme ? darkTheme.body : lightTheme.inputBG,
            color: isDarkTheme ? darkTheme.text : lightTheme.text,
        })
    }

    //api for posting user details

    let nameregex = /^[a-zA-Z ]{2,50}$/
    let postcoderegex = /^[0-9a-zA-Z]+$/
    let address = /^[A-Za-z0-9\s\.,#\-]+$/
    let formData = new FormData();

    const formik = useFormik({
        // initialValues: { ...initialValues, },
        initialValues: initialData,
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            name: Yup.string().min(3).max(50).matches(nameregex, "Enter a Valid Name, accepting alphabets only"),

            phone: Yup.string()
                .required('Phone number is required')
                .min(5, 'Phone number must be at least 5 characters')
                .max(15, 'Phone number must not exceed 15 characters'),

            addressLine1: Yup.string().min(5, 'Address Must Be Atleast 5 Characters').matches(address, 'Address doesnot contain any special character'),
            postcode: Yup.string().min(2).matches(postcoderegex, "Enter a Valid postal code, accepting numbers only"),
            city: Yup.object({
                value: Yup.string()
            }),
            state: Yup.object({
                value: Yup.string()
            }),
            country: Yup.object({
                value: Yup.string()
            }),
            nickname: Yup.string(),
            bio: Yup.string(),
        }),
        onSubmit: (values, { setSubmitting }) => {
            setLoading(true)
            setSubmitting(true)
            // console.log(values)
            axios.post(API_URLs.profile2Fa, {}, {
                headers: {
                    accept: 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('jwt')}`
                }
            })
                .then((res) => {
                    handleOpenOTP();
                    setLoading(false)
                    setOtpData(res.data)
                })
                .catch((error) => {
                    console.log('Error', error);
                    setLoading(false)
                })
        },
    });

    const handleFileChange = (element) => {
        let file = element.target.files[0];
        let reader = new FileReader();
        setProfileImageElement(element.target);
        reader.onloadend = function () {
            formik.values.profileImage = reader.result;
            setProfileImageBlob(reader.result)
        }
        reader.readAsDataURL(file);
        setShow(false)
    }

    const handleCheckOTP = () => {
        if (formik.values.name?.length > 0) {
            formData.append('name', formik.values.name)
        }
        formData.append('mobile', formik.values.phonecode?.value + formik.values.phone)
        formData.append('email', formik.values.email)
        if (formik.values.addressLine1?.length > 0) {
            formData.append('address', formik.values.addressLine1)
        }
        if (formik.values.postcode?.length > 0) {
            formData.append('postcode', formik.values.postcode)
        }
        if (formik.values.city?.value?.length > 0) {
            formData.append('city', formik.values.city?.value)
        }
        if (formik.values.state?.value?.length > 0) {
            formData.append('state', formik.values.state?.value)
        }
        if (formik.values.country?.value?.length > 0) {
            formData.append('country', formik.values.country?.value)
        }
        if (profileImageElement?.files) {
            formData.append('avatar', profileImageElement?.files ? profileImageElement.files[0] : "")
        }
        formData.append('otp', otpValue)
        formData.append('hash', otpData.response.hash)
        if (formik?.values?.bio?.length > 0) {
            formData.append('bio', formik.values.bio)
        }

        handleAddProfile()
    }

    const handleAddProfile = () => {
        setLoading(true)
        axios.patch(API_URLs.profileUpdate, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                "Authorization": `Bearer ${localStorage.getItem('jwt')}`
            }
        })
            .then((res) => {
                setLoading(false)
                setIsEditable(false)
                getUserDetails();
                handleCloseOTP();
                setModalData(res);
                handleShowModal(res?.data?.msg ? res?.data?.msg : "Profile Updated Successfully!!");
            })
            .catch((err) => {
                setLoading(false)
                setModalData(err);
                handleCloseOTP();
                handleShowModal(err?.response?.data?.message ? err?.response?.data?.message : 'Some Error Occured');
            })
    }

    const handleFetchState = async (countryID) => {
        try {
            let list = []

            if (countryID) {
                let res = await fetchStates(countryID)

                let filteredCountry = countryOptions?.filter((country) => {
                    return country?.value === countryID
                })

                formik.setValues({
                    ...formik.values, country: filteredCountry[0], state: "", city: ""
                })

                list = res.map((st) => {
                    return {
                        value: st?.code,
                        label: st?.name
                    }
                })

                setStateList(list)
            }
            return list
        } catch (error) {
            return []
        }
    }

    const handleFetchCity = async (countryId, stateId) => {
        try {
            let list = [];

            if (countryId && stateId) {
                let res = await fetchCities(countryId, stateId)

                let filteredState = stateList?.filter((state) => {
                    return state?.value === stateId
                })

                formik.setValues({
                    ...formik.values, state: filteredState[0], city: ""
                })

                list = res.map((ct) => {
                    return {
                        value: ct?.name,
                        label: ct?.name
                    }
                })

                setCityList(list)
            }
            return list
        } catch (error) {
            return []
        }
    }
    const handleVerfyMobile = async () => {

        const fullPhoneNumber = formik.values.phonecode?.value + formik.values.phone;
        axios.post(API_URLs.sendMobileOTP, {
            phone: fullPhoneNumber,
            phoneCode: formik.values.phonecode?.value
            // phone: phone.value + phoneNumber
        }, {
            headers: {
                accept: 'application/json',
                Authorization: `Bearer ${localStorage.getItem('jwt')}`
            }
        })
            .then((res) => {
                localStorage.setItem('mobile', res.data.tokens)
                // handleShowModal(res?.data?.msg ? res?.data?.msg : "OTP sent to your Phone Number!!");
                handleOpenMobileOTP();
                setTime(600);
            })
            .catch((err) => {
                handleShowModal(err?.response?.data?.message ? err?.response?.data?.message : 'Some Error Occured');
            })
    }
    const handleVerfyEmail = async (email) => {
        axios.post(API_URLs.verifyEmail, {
            email
        }, {
            headers: {
                accept: 'application/json',
                Authorization: `Bearer ${localStorage.getItem('jwt')}`
            }
        })
            .then((res) => {
                localStorage.setItem('emailverification', res.data.tokens)
                // handleShowModal(res?.data?.msg ? res?.data?.msg : "OTP sent to your Email!!");
                handleOpenEmailOTP();
                setTime(600);
            })
            .catch((err) => {
                handleShowModal(err?.response?.data?.message ? err?.response?.data?.message : 'Some Error Occured');
            })
    }
    useEffect(() => {
        handleSetNavbarHeading("Setting")
        if (userDetails?.user?.isProfileDone === "false") {
            setIsEditable(true)
        }
        axios.get(API_URLs.getUser, {
            headers: {
                'accept': 'application/json',
                "Authorization": `Bearer ${localStorage.getItem('jwt')}`
            }
        })
            .then((res) => {
                let filteredCountry = countryOptions?.filter((country) => {
                    return country?.value === res.data.user.country?._id
                })
                handleFetchState(filteredCountry[0]?._id).then((stateResult) => {

                    let filteredState = stateResult?.filter((state) => {
                        return state?.value === res.data.user?.state
                    })
                    handleFetchCity(filteredCountry[0]?._id, filteredState[0]?.value).then((cityResult) => {
                        let filteredCity = cityResult?.filter((city) => {
                            return city?.value === res.data.user?.city
                        })
                        let filteredCurrency = fiatCurrencyOptions?.filter((currency) => {
                            return currency?.symbol === res?.data?.user?.currency
                        })
                        let selectPhoneCode = phoneCode.filter((phone) => {
                            return phone.value === res?.data?.user?.phoneCode
                        })
                        const updatedInitialData = {
                            name: res.data.user.name,
                            phone: res?.data?.user?.mobile?.substring(res?.data.user?.phoneCode?.length,),
                            addressLine1: res.data.user.address,
                            email: res.data.user.email,
                            bio: res.data.user.bio,
                            city: filteredCity[0],
                            postcode: res.data.user.postcode,
                            state: filteredState[0],
                            address: res.data.user.address,
                            country: filteredCountry[0],
                            profileImage: res.data.user.avatar,
                            nickname: res.data.user.nickNames,
                            currency: filteredCurrency[0],
                            phonecode: selectPhoneCode[0]
                        };
                        formik.setValues(updatedInitialData);
                        setInitialData(updatedInitialData);
                        setProfileImageBlob(res.data.user.avatar)
                        localStorage.setItem('email', res.data.user.email);
                    })
                })
            })
            .catch((error) => {
            })
    }, [])

    const styles = {
        control: (styles) => ({
            ...styles,
            borderRadius: '20px',
            outline: 'none',
            border: `none`, "&:hover": {
                border: `none`
            },
            boxShadow: `none`,
            color: 'white',

        }),

        input: (styles) => ({
            ...styles,
            padding: '0 30px',
        }),

        option: (provided, state) => ({
            ...provided,
            backgroundColor: isDarkTheme && state.isHovered ? '#302F30' : `${({ theme }) => theme.body}`,
            ':hover': {
                backgroundColor: `${isDarkTheme ? '#302F30' : '#D9EDE7'}`,
            },
            color: isDarkTheme && state.isSelected ? '#1476FF' : `${({ theme }) => theme.text}`,

        }),
        indicatorsContainer: (styles) => ({
            ...styles, color: isDarkTheme ? 'white' : 'black', 'div:nth-child(2)': {
                color: isDarkTheme ? 'white' : 'black',

            }
        }),
        menu: (styles) => ({
            ...styles, background: isDarkTheme ? 'rgb(27, 27, 28)' : 'white',
            color: isDarkTheme ? 'white' : 'black',
            width: '28vw'
        })
    }

    const filterOptions = (candidate, input) => {
        if (!input) return true;
    
        const inputValue = input.toLowerCase();
        const label = candidate.label.toLowerCase();
        
        return label.startsWith(inputValue) || label.includes(inputValue);
    };

    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false)
    };
    const handleShow = () => setShow(true);

    const [time, setTime] = useState(600);
    useEffect(() => {
        const interval = setInterval(() => {
            if (time > 0) {
                setTime(time - 1);
            } else {
                clearInterval(interval);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [time]);

    const handleFormCancel = () => {
        formik.resetForm({ values: initialData });
        setIsEditable(false);
    };

    useDetectFormChanges(formik.dirty, navigate);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    let modifiedDropdownValue = { label: formik?.values.phonecode ? formik?.values.phonecode.value : '', value: '' }
    let placeholderText = modifiedDropdownValue.label ? '' : 'Search';


    // console.log('modifiedDropdownValueeee', modifiedDropdownValue)
    return (
        <>
            <div className='container px-0 '>
                <Div className='card p-3 p-md-4'>
                    <Heading>Profile</Heading>
                    <hr></hr>
                    <div className='d-flex flex-wrap justify-content-between align-items-center'>
                        <div className='d-flex flex-wrap justify-content-center align-items-center gap-3'>
                            {
                                profileImageBlob ?
                                    <ProfileImg
                                        src={profileImageBlob}
                                    /> :
                                    <ProfileImg
                                        src={userDetails?.user?.avatar}
                                    />
                            }
                            <div>
                                <ProfileHeading>{userDetails?.user?.nickNames}</ProfileHeading>
                                {isEditable && <WhiteButton disabled={loading} onClick={(e) => {
                                    e.preventDefault()
                                    setIsEditable(true)
                                    handleShow()
                                }} className='px-4 py-1'>
                                    {
                                        loading ? <><Oval height={13} width={13} color='black' /> Sending..</> : <p className='mb-0'>Edit Profile Photo</p>
                                    }
                                </WhiteButton>}
                            </div>
                        </div>
                        <div className='d-none d-lg-block'>

                            {!isEditable && <BlueButton disabled={loading} onClick={(e) => {
                                e.preventDefault()
                                setIsEditable(true)
                            }} className='me-3'>
                                {
                                    loading ? <><Oval  color='black' /> Sending..</> : <p className='mb-0 text-white'>Edit Profile</p>
                                }
                            </BlueButton>}

                            <BlueButton target='_blank' onClick={() => navigate(`/dashboard/user/${userDetails?.user?._id}`)} className='text-white'>View Public Profile</BlueButton>
                        </div>
                    </div>
                    <div className='d-lg-none text-center mt-2 d-block'>
                        <BlueButton1 target='_blank' onClick={() => navigate(`/dashboard/user/${userDetails?.user?._id}`)} className='text-white'>View Public Profile</BlueButton1>
                    </div>
                    <hr></hr>
                    <form onSubmit={(e) => { e.preventDefault(); formik.handleSubmit() }} className='mt-2'>
                        <ProfileHeading className='px-0'>Basic information</ProfileHeading>
                        <small className='text-secondary'>This information is public and will be displayed on your public profile.</small>
                        <div className='row m-0'>
                            <div className='d-flex my-3 flex-column col-md-6 col-sm-12'>
                                <InputLabel htmlFor="nickname">User Name<span className='text-danger text-danger-error'> *</span></InputLabel>
                                <Input
                                    id="nickname"
                                    name="nickname"
                                    type="text"
                                    placeholder='Enter your username'
                                    className='profile-input'
                                    disabled={true}
                                    value={formik.values.nickname}
                                    onChange={(event) => {
                                        // console.log(event)
                                        formik.handleChange(event)
                                    }}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.nickname && formik.errors.nickname ? (
                                    <div className='text-danger text-danger-error user-validation'>{formik.errors.nickname}</div>
                                ) : null}

                            </div>
                        </div>
                        <div className='row m-0'>
                            <div className='d-flex my-3 flex-column col-md-6 col-sm-12'>
                                <InputLabel htmlFor="name">Bio</InputLabel>
                                <Input
                                    id="bio"
                                    name="bio"
                                    type="text"
                                    placeholder='Enter your bio'
                                    className='profile-input'
                                    disabled={!isEditable}
                                    value={formik.values.bio}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                        </div>
                        <hr></hr>
                        <ProfileHeading className='px-0'>Personal Info.</ProfileHeading>
                        <small className='text-secondary'>This information is private and will not be displayed on your public profile.</small>
                        <div className='row m-0'>
                            <div className='d-flex my-3 flex-column col-md-6 col-sm-12'>
                                <InputLabel htmlFor="name">Name</InputLabel>
                                <Input
                                    id="name"
                                    name="name"
                                    type="text"
                                    placeholder='Enter your Name'
                                    className='profile-input'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.name}
                                    disabled={!isEditable}
                                />
                                {formik.touched.name && formik.errors.name ? (
                                    <div className='text-danger text-danger-error user-validation'>{formik.errors.name}</div>
                                ) : null}
                            </div>
                        </div>
                        <div className='row m-0'>
                            <div className=' d-flex my-3 flex-column col-md-6 col-sm-12'>
                                <InputLabel htmlFor="email">Email Address<span className='text-danger text-danger-error'> *</span></InputLabel>
                                <div className='profEmail'>
                                    <InputContainer>
                                        <Input1
                                            id="email"
                                            style={{ width: '60%' }}
                                            name="email"
                                            placeholder='Enter your Email'
                                            type="email"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.email}
                                            disabled={!changeEmail}
                                        />
                                        {(userDetails?.user?.email?.length > 1 || formik?.values?.email?.length > 1) && <>{(!userDetails?.user?.isEmailVerified || userDetails?.user?.email != formik?.values?.email) ? <VerifyButton onClick={() => handleVerfyEmail(formik?.values?.email)} type='button'>Verify</VerifyButton> : <VerifyButton disabled={true} type='button'>Verified</VerifyButton>}</>}
                                    </InputContainer>
                                </div>
                                <p className={`mb-0 ${changeEmail ? 'faded-text' : ''}`}>{changeEmail ? 'Change Your Email ID' : <ChangeText onClick={() => setChangeEmail(true)}>Change Your Email ID</ChangeText>}</p>
                                {formik.touched.email && formik.errors.email ? (
                                    <div className='text-danger text-danger-error user-validation'>{formik.errors.email}</div>
                                ) : null}
                            </div>
                        </div>
                        <div className='row m-0'>
                            <div className='d-flex my-3 flex-column  col-md-5 col-sm-12'>
                                <InputLabel htmlFor="phone">Phone Number<span className='text-danger text-danger-error'> *</span></InputLabel>
                                <div className='profile-select d-flex justify-content-between position-relative w-100'>

                                    {!isSearching && (
                                        <div style={{ position: 'absolute', top: '8px', left: '17px', color: '#a0a0a0', zIndex: '999', fontSize: '16px' }}>{placeholderText}</div>
                                    )}
                                    <Select
                                        id='phonecode'
                                        className='selct-drop-profile'
                                        name='phonecode'
                                        options={phoneCode}
                                        value={modifiedDropdownValue}
                                        onChange={(selectedValues) => {
                                            let event = { target: { name: 'phonecode', value: selectedValues } }
                                            // setSelectedCountryCxode(selectedValues)
                                            formik.handleChange(event)
                                        }}
                                        onBlur={(selectedValues) => {
                                            let event = { target: { name: 'phonecode', value: selectedValues } }
                                            formik.handleBlur(event)
                                        }}
                                        filterOption={filterOptions}
                                        styles={styles}
                                        onInputChange={(inputValue) => setIsSearching(!!inputValue.trim())}
                                        disabled={!changeMobile}

                                    />
                                    <InputContainer>
                                        <Input1
                                            id="phone"
                                            name="phone"
                                            placeholder='Enter your Phone Number'
                                            type="text"
                                            maxLength={15}
                                            style={{ width: '60%' }}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.phone}
                                            disabled={!changeMobile}
                                        />
                                        {(userDetails?.user?.phone?.length > 1 || formik?.values?.phone?.length > 1) && <>{(!userDetails?.user?.isMobileVerified || userDetails?.user?.mobile?.substring(userDetails?.user?.phoneCode?.length,) !== formik?.values?.phone) ? <VerifyButton onClick={() => handleVerfyMobile()} type='button'>Verify</VerifyButton> : <VerifyButton disabled={true} type='button'>Verified</VerifyButton>}</>}
                                    </InputContainer>
                                </div>
                                <p className={`mb-0 ${changeMobile ? 'faded-text' : ''}`}>
                                    {changeMobile ? 'Change Your Mobile Number' : <ChangeText onClick={() => setChangeMobile(true)}>Change Your Mobile Number</ChangeText>}</p>
                                {formik.touched.phone && formik.errors.phone ? (
                                    <div className='text-danger text-danger-error user-validation'>{formik.errors.phone}</div>
                                ) : null}
                            </div>
                        </div>
                        <div className='d-flex flex-column col-md-12 col-sm-12'>
                            <InputLabel htmlFor="addressLine1">Address</InputLabel>
                            <div className='w-full d-flex flex-wrap gap-4'>
                                <div className='d-flex flex_grow flex-column '>
                                    <Input
                                        id="addressLine1"
                                        name="addressLine1"
                                        placeholder='Street Address'
                                        type="text"
                                        className='profile-input'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.addressLine1}
                                        disabled={!isEditable}
                                    />
                                    {formik.touched.addressLine1 && formik.errors.addressLine1 ? (
                                        <div className='text-danger text-danger-error user-validation'>{formik.errors.addressLine1}</div>
                                    ) : null}
                                </div>
                                <div className='d-flex flex_grow flex-column'>
                                    <Input
                                        id="postcode"
                                        name="postcode"
                                        type="text"
                                        placeholder='Postal Code'
                                        className='profile-input'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.postcode}
                                        disabled={!isEditable}
                                    />
                                    {formik.touched.postcode && formik.errors.postcode ? (
                                        <div className='text-danger text-danger-error user-validation'>{formik.errors.postcode}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className='d-flex flex-wrap w-full gap-3 mt-3'>
                                <div className='d-flex flex_grow flex-column'>
                                    {
                                        isEditable ?
                                            <Select options={countryOptions || []}
                                                isDisabled={!isEditable}
                                                menuPlacement="top"
                                                placeholder='Country'
                                                filterOption={customFilterForCountry}
                                                styles={SelectStyles}
                                                name='country'
                                                id='country'
                                                onChange={(selectedValues) => {
                                                    let event = { target: { name: 'country', value: selectedValues } }
                                                    // formik.setValues({
                                                    //     ...formik.values, phone: selectedValues?.phone_code
                                                    // })
                                                    formik.handleChange(event)
                                                    handleFetchState(selectedValues?.value, selectedValues?.phone_code)
                                                }}
                                                onBlur={(selectedValues) => {
                                                    let event = { target: { name: 'country', value: selectedValues } }
                                                    formik.handleBlur(event)
                                                }}
                                                value={formik.values.country}
                                            /> :
                                            <Input
                                                type="text"
                                                placeholder='State'
                                                className='profile-input'
                                                value={formik.values.country?.name}
                                                disabled={!isEditable}
                                            />
                                    }
                                    {formik.touched.country && formik.errors.country ? (
                                        <div className='text-danger text-danger-error user-validation'>{formik.errors.country?.value}</div>
                                    ) : null}
                                </div>
                                <div className='d-flex flex_grow flex-column' >
                                    {
                                        isEditable ?
                                            <Select options={stateList}
                                                isDisabled={!isEditable}
                                                placeholder='State'
                                                menuPlacement="top"
                                                styles={SelectStyles}
                                                name='state'
                                                id='state'
                                                onChange={(selectedValues) => {
                                                    let event = { target: { name: 'state', value: selectedValues } }
                                                    handleFetchCity(formik.values.country?.value, selectedValues?.value)
                                                    formik.handleChange(event)
                                                }}
                                                onBlur={(selectedValues) => {
                                                    let event = { target: { name: 'state', value: selectedValues } }
                                                    formik.handleBlur(event)
                                                }}
                                                value={formik?.values?.state}
                                            /> :
                                            <Input
                                                name="state"
                                                type="text"
                                                placeholder='State'
                                                className='profile-input'
                                                value={formik.values.state?.label}
                                                disabled={!isEditable}
                                            />
                                    }

                                    {formik.touched.state && formik.errors.state ? (
                                        <div className='text-danger text-danger-error user-validation'>{formik.errors.state?.value}</div>
                                    ) : null}
                                </div>
                                <div className='d-flex flex_grow flex-column '>
                                    {
                                        isEditable ?
                                            <Select options={cityList}
                                                isDisabled={!isEditable}
                                                placeholder='City'
                                                menuPlacement="top"
                                                styles={SelectStyles}
                                                name='city'
                                                id='city'
                                                onChange={(selectedValues) => {
                                                    let event = { target: { name: 'city', value: selectedValues } }
                                                    formik.handleChange(event)
                                                }}
                                                onBlur={(selectedValues) => {
                                                    let event = { target: { name: 'city', value: selectedValues } }
                                                    formik.handleBlur(event)
                                                }}
                                                value={formik?.values?.city}
                                            /> :
                                            <Input
                                                name="city"
                                                type="text"
                                                placeholder='City'
                                                className='profile-input'
                                                value={formik.values.city?.label}
                                                disabled={!isEditable}
                                            />
                                    }
                                    {formik.touched.city && formik.errors.city ? (
                                        <div className='text-danger text-danger-error user-validation'>{formik.errors.city?.value}</div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className='my-2'>
                            {
                                isEditable &&
                                <BlueButton disabled={loading} type='submit' className='px-4 mt-3 py-2'>
                                    {
                                        loading ?
                                            <span className='d-flex'><Oval height={13} width={13} color='black' /> Sending..</span> :
                                            <>
                                                {
                                                    userDetails?.user?.isProfileDone === "false" ? "Add Profile" : "Update Profile"
                                                }
                                            </>
                                    }
                                </BlueButton>
                            }
                            {
                                isEditable &&
                                <WhiteButton onClick={handleFormCancel} className='px-4 mt-3 py-2'>
                                    Cancel
                                </WhiteButton>
                            }


                        </div>
                    </form>
                </Div>

                <Modal show={isProfileDoneOTP}>
                    <Modal.Header className='d-flex justify-content-between'>
                        {/* <Modal.Title>Verify its you</Modal.Title> */}
                        <RxCrossCircled onClick={() => handleCloseOTP()} size={23} />
                    </Modal.Header>
                    <Modal.Body>
                        <div className='mb-1'>
                            <Para className='text-center' >We have sent successfully verification code to your email address</Para>
                        </div>
                        <OTPInput
                            className='OtpInputBox mb-2'
                            value={otpValue}
                            onChange={setOtpValue}
                            autoFocus
                            OTPLength={4}
                            otpType="number"
                            disabled={false}
                            secure
                        />
                        <Para className='text-center pt-3'>Verification Code will expire in {formatTime(time)} minutes</Para>
                        <div className='d-flex justify-content-center mt-2'>
                            <BlueButton onClick={() => handleCheckOTP()}>
                                {loading ?
                                    <>
                                        <Oval
                                            height={13}
                                            width={13}
                                            color='white'
                                        />Checking..</> :
                                    <p className='mb-0 text-white'>Submit</p>
                                }
                            </BlueButton>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={mobileOTP}>
                    <Modal.Header className='d-flex justify-content-between'>
                        {/* <Modal.Title>Verify its you</Modal.Title> */}
                        <RxCrossCircled onClick={() => handleCloseMobileOTP()} size={23} />
                    </Modal.Header>
                    <Modal.Body>
                        <div className='mb-1'>
                            <Para className='text-center' >We have sent successfully verification code to your phone number</Para>
                        </div>
                        <OTPInput
                            className='OtpInputBox mb-2'
                            value={otpValue}
                            onChange={setOtpValue}
                            autoFocus
                            OTPLength={4}
                            otpType="number"
                            disabled={false}
                            secure
                        />
                        {time === 0 ?
                            <Para className='text-center pt-3'>Verification Code is expired</Para> :
                            <Para className='text-center pt-3'>Verification Code will expire in {formatTime(time)} minutes</Para>
                        }
                        <div className='d-flex justify-content-center mt-2'>
                            <BlueButton onClick={() => handleCheckMobileOTP(otpValue)}>
                                {loading ?
                                    <>
                                        <Oval
                                            height={13}
                                            width={13}
                                            color='white'
                                        />Checking..</> :
                                    <p className='mb-0'>Submit</p>
                                }
                            </BlueButton>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={emailOTP}>
                    <Modal.Header className='d-flex justify-content-between'>
                        {/* <Modal.Title>Verify its you</Modal.Title> */}
                        <RxCrossCircled onClick={() => handleCloseEmailOTP()} size={23} />
                    </Modal.Header>
                    <Modal.Body>
                        <div className='mb-1'>
                            <Para className='text-center' >We have sent successfully verification code to your email address</Para>
                        </div>
                        <OTPInput
                            className='OtpInputBox mb-2'
                            value={otpValue}
                            onChange={setOtpValue}
                            autoFocus
                            OTPLength={4}
                            otpType="number"
                            disabled={false}
                            secure
                        />
                        {time === 0 ?
                            <Para className='text-center pt-3'>Verification Code is expired</Para> :
                            <Para className='text-center pt-3'>Verification Code will expire in {formatTime(time)} minutes</Para>
                        }
                        <div className='d-flex justify-content-center mt-2'>
                            <BlueButton onClick={() => handleCheckEmailOTP(otpValue)}>
                                {loading ?
                                    <>
                                        <Oval
                                            height={13}
                                            width={13}
                                            color='white'
                                        />Checking..</> :
                                    <p className='mb-0'>Submit</p>
                                }
                            </BlueButton>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal className='modalEditPic' show={show} onHide={handleClose} >
                    <Modal.Header className='border-0' closeButton>
                        <Modal.Title className='border-0 modalTitleWidth' onClick={() => {

                        }}>Edit Profile Photo</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            isEditable ? <div className='d-flex gap-3 flex-column align-items-center justify-content-center'>
                                <span onClick={() => {
                                    profileImageRef.current.click()
                                }} className='editProfilepicbtn d-flex align-items-center justify-content-center'>
                                    <MdEdit size={25} className='text-primary text-white' />
                                    Update Profile Photo
                                </span>
                                <span onClick={() => {
                                    setProfileImageBlob('')
                                    setShow(false)
                                    setIsEditable(false)
                                }} className='editProfilepicbtn d-flex align-items-center justify-content-center'>
                                    <MdOutlineDeleteOutline size={25} className='text-danger text-danger-error' />
                                    Delete Profile Photo
                                </span>
                                <input onChange={handleFileChange}
                                    name='profileImage'
                                    type="file"
                                    className='d-none'
                                    ref={profileImageRef} />
                            </div> : ''
                        }
                    </Modal.Body>
                </Modal>

                {/* Update Profile Modal  */}
                {showModal && (<CustomModal
                    show={showModal}
                    handleClose={handleCloseModal}
                    data={modalData}
                    content={modalData}
                />
                )}
            </div>

        </>
    )
}
