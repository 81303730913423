import React, { useEffect, useRef, useState } from 'react'
import { useAuthContextHook } from "../../../Context/AuthContext";
import styled from 'styled-components';
import OTPInput from "otp-input-react";
import '../AuthCss/Login.css'
import { Link } from 'react-router-dom';
import Logo from '../../../assets/Images/LogoBlue.png'
import CustomModal from '../../../hooks/CustomModal';
import ButtonLoader from '../../../Component/Loader/ButtonLoader';
import Icon from '../../../images/Home/qvaniIcon.png'

const Container = styled.div`
background: white;
border-radius: 14px;
max-width: 430px;
background: ${({theme})=>theme.body === "#F7F9FC" ? 'white': theme.body};
border: ${({theme})=>theme.border};
box-shadow: ${({theme})=>theme.boxshadow};
`
export const Para = styled.p`
font-size: 14px;
font-weight: 500;
border: none;
color: ${({theme})=>theme.text};
background: transparent;
`
const ParaDiv = styled.div`
font-size: 0.9rem;
font-weight: 500;
transition: all 0.2s;
`
const ResizePara = styled.button`
    border: none;
    background: transparent;
    :hover{
        opacity: 0.8;
    }
    /* &:active {
    transform: scale(0.95);
    } */
`
const P = styled.p`
font-size: 13px;
font-weight: 500;
`

export default function Otp() {
    const Ref = useRef(null);

    const { handleLoginOTPVerification, handleLogin, cred, showModal, handleCloseModal, navigateLink, modalData} = useAuthContextHook();
    const [loading, setLoading] = useState(false);
    const [otpValue, setOtpValue] = useState('');
    const [otpLoader, setOtpLoader] = useState(false)
    const loginThrough = localStorage.getItem('loginThrough')
    
    const [time, setTime] = useState(600);
    useEffect(() => {
      const interval = setInterval(() => {
        if (time > 0) {
          setTime(time - 1);
        } else {
          clearInterval(interval);
        }
      }, 1000);

      return () => clearInterval(interval);
    }, [time]);
  
    const formatTime = (seconds) => {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true)
            await handleLoginOTPVerification(otpValue);
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }
    const handleResetOtp = async (e) => {
        e.preventDefault();
        if (time === 0 && otpLoader === false) {
            try {
                setOtpLoader(true)
                await handleLogin(cred.email, cred.password)
                setOtpLoader(false)
                setTime(600);
            } catch (error) {
                setOtpLoader(false)
            }
        }
    }

    return (
        <div className='LoginBg w-full row m-0 d-flex justify-content-center align-items-center'>
            <div className='p-3 mb-2' style={{ minHeight: '0' }}>
                <Link to='/home'><img src={Logo} alt="" width={'140px'} /></Link>
            </div>
            <div className='d-flex align-items-center justify-content-center'>
                <Container className="card col-12 col-md-6 col-xl-4 pt-3 px-2">
                    <div className='card-head mt-2 d-flex flex-column'>
                        <img src={Icon} alt='' width='60px' className='align-self-center' />
                        <p className="card-heading text-center pt-1 mb-1 fs-2 fw-bolder">Welcome To Qvani</p>
                    </div>
                    <div className="card-body py-0">
                        <form >
                            <div className='FormDiv d-flex m-0 flex-column'>
                                <Para className='text-center text-secondary text-break'>We have sent a verification Code to your {loginThrough ==='email' ? 'e-mail address' : 'phone number'}.</Para>
                                <OTPInput
                                    className='OtpInputBox'
                                    value={otpValue}
                                    onChange={setOtpValue}
                                    autoFocus
                                    OTPLength={4}
                                    otpType="number"
                                    disabled={false} secure
                                />
                            </div>
                            {time === 0 ?
                                <Para className='text-center pt-3 mb-1'>Verification code has been expired</Para> :
                                <Para className='text-center pt-3 mb-1'>Verification code will expire in {formatTime(time)} minutes</Para>
                            }
                            <ParaDiv className='text-center mt-0 pt-0 d-flex flex-column' >
                                Didn’t Get Verification code ?
                                <ResizePara
                                    className='text-primary m-0'
                                    disabled={time !== 0}
                                    onClick={handleResetOtp}>
                                    {
                                        otpLoader === true ? 'Sending..' : 'Resend Verification Code'
                                    }
                                </ResizePara>
                            </ParaDiv>
                            <div className='d-flex justify-content-center mb-4 mt-4'>
                                <button className='Button mt-0 d-flex justify-content-center' onClick={onSubmit} type="submit" disabled={loading || otpValue.length < 4}>
                                    {
                                        loading ? <><ButtonLoader /> <span>checking..</span></> : 'Submit'
                                    }
                                </button>
                            </div>
                        </form>
                    </div>
                </Container>
            </div>
            {showModal && (<CustomModal
                show={showModal}
                handleClose={handleCloseModal}
                navigateLink={navigateLink}
                data={modalData}
            />)}
        </div>
    );
}