import React, { useState } from 'react'
import DataTable, {createTheme} from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import './Index.css'
import { API_URLs } from '../../Utils/ApiUrls';
import axios from 'axios';
import CustomModal from '../../hooks/CustomModal';
import { AiOutlineEye } from 'react-icons/ai'
import { MdOutlineBlock } from 'react-icons/md'
import { CgUnblock } from 'react-icons/cg'
import styled from 'styled-components';
import { Oval } from 'react-loader-spinner';
import DataLoader from '../../Component/Loader/DataLoader';
import { useThemeContext } from '../../Context/ThemeContext';
const P = styled.p`
margin-bottom: 0px;
font-size: 16px;
margin-top: 2px;
`
const Button = styled.button`
background: white;
font-weight: 600;
font-size: 13px;
font-family: 'Inter';
width: 5rem;
border-radius: 15px;
border: 1px solid #E9EAF3;
outline: none;
transition: all 0.2s;
display: flex;
padding: 5px 1px;
justify-content: center;
align-items: center;
:hover{ 
    border: 1px solid #1476FF;
    background:  #1476FF;
    color: white;
}
`
const Tag = styled.p`
font-size: 12px;
margin-top: 2px;
font-weight: 600;
background: #d9d9d9;
margin-bottom: 0px;
border-radius: 10px;
padding: 2px 5px;
`
const Div = styled.div`
font-size: 15px;
align-items: center;
gap: 10px;
color: darkred;
`
const Heading = styled.p`
font-weight: 700 !important;
font-size: 14px;
margin-bottom: 0px;
`
export default function Selloffer({ sellofferdata, getAllOffers, dataLoader }) {

    const navigate = useNavigate()
    const {isDarkTheme} = useThemeContext();
    createTheme('solarized', {
        background: {
            default: `${isDarkTheme ? "#0c0c0c" : 'white'}`
        },
        action: {
            hover: 'rgba(0,0,0,.08)',
        },
        text: {
            secondary: `${isDarkTheme ? "white" : 'black'}`
        },
    });
    const customStyles = {
        header: {
            style: {
                fontSize: '20px',
                color: '#0B0E2C',
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: '600',
            },
        },
        headRow: {
            style: {
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontSize: '12px',
                ...( isDarkTheme ? {borderTop: '1px solid #302f30'}:
                { border: '1px solid lightgray'}),
                color: `${isDarkTheme ? "#e9e9e9":'#6F7182'}`,
                backgroundColor: `${isDarkTheme ? "#0c0d0d":"#F7F9FC"}`,
                textTransform: 'uppercase'
            },
        },
        rows: {
            style: {
                color: `${isDarkTheme && 'white'}`,
                backgroundColor: `${isDarkTheme && 'rgba(8, 9, 14, 0.96)'}`,
                borderTop: '1px solid lightgray',
                '&:not(:last-of-type)': {
                    borderBottom: 'none',
                },
            }
        },
        headCells: {
            style: {
                display: 'flex',
                alignItems: 'center'
            },
        },
        cells: {
            style: {
                display: 'flex',
                alignItems: 'center'
            },
        }
    }
    const [blockLoader, setBlockLoader] = useState(false);
    const [id, setId] = useState('');
    const [unblockLoader, setUnblockLoader] = useState(false);
    const columns = [
        {
            name: <Heading>Rate</Heading>,
            selector: row => {
                return <div className='d-flex flex-row'>
                    <P>rates  {row.offer_margin}%</P>
                </div>
            },
            wrap: true,
        },
        {
            name: <Heading>Currency</Heading>,
            selector: row => {
                return <div className='d-flex flex-row'>
                    <P>{row.cryptoCurrency.name}</P>
                </div>
            },
            wrap: true,
        },
        {
            name: <Heading>Min-Max Amount</Heading>,
            selector: row => {
                return <div className='d-flex flex-row'>
                    <P>{row.trade_limit.min}-{row.trade_limit.max} {row.preffered_currency.symbol}</P>
                </div>
            },
            wrap: true,
        },
        {
            name: <Heading>Payment Methods</Heading>,
            selector: row => {
                return <div className='d-flex flex-column'>
                    <P>{row.payment_method}</P>
                    {
                        row.offer_tags?.length > 0 && row.offer_tags.map((val, index) => {
                            return (
                                <Tag key={index}>{val}</Tag>
                            );
                        })
                    }
                </div>
            },
            wrap: true,
        },
        {
            name: <Heading>Action</Heading>,
            selector: row => {
                return <div className='d-flex gap-1'>
                    <Button className='viewoffersbuttonfortour' disabled={row.isBlocked} onClick={() => navigate(`/dashboard/offer/${row._id}`)}>View <AiOutlineEye size={14} /></Button>
                    {row.isBlocked ?
                        <Button type='button' className='offerlistpauseresumefortour' disabled={unblockLoader} onClick={() => { handleUnBlockOffer(row._id) }}>
                            {unblockLoader ?
                                id === row._id ?
                                    <Oval
                                        height={15}
                                        width={15}
                                        color="black"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        visible={true}
                                        ariaLabel="oval-loading"
                                        secondaryColor="#4fa94d"
                                        strokeWidth={2}
                                        strokeWidthSecondary={2}
                                    /> : <p className='mb-0 d-flex align-items-center'>Resume <CgUnblock size={14} /></p>
                                : <p className='mb-0 d-flex align-items-center'>Resume <CgUnblock size={14} /></p>}
                        </Button> :
                        <Button type='button' className='offerlistpauseresumefortour' disabled={blockLoader} onClick={() => { handleBlockOffer(row._id) }}>
                            {
                                blockLoader ?
                                    id === row._id ?
                                        <Oval
                                            height={15}
                                            width={15}
                                            color="black"
                                            wrapperStyle={{}}
                                            wrapperClass=""
                                            visible={true}
                                            ariaLabel="oval-loading"
                                            secondaryColor="#4fa94d"
                                            strokeWidth={2}
                                            strokeWidthSecondary={2}
                                        /> : <p className='mb-0 d-flex align-items-center'>Pause <MdOutlineBlock size={14} /></p>
                                    : <p className='mb-0 d-flex align-items-center'>Pause <MdOutlineBlock size={14} /></p>
                            }
                        </Button>}
                </div>
            },
            wrap: true,
        }
    ]

    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [navigateLink, setNavigateLink] = useState('');
    const handleShowModal = (data) => {
        setModalData(data);
        setShowModal(true);
    };
    const handleCloseModal = () => {
        setShowModal(false)
    };

    const handleBlockOffer = (id) => {
        setId(id);
        setBlockLoader(true);
        axios.put(API_URLs.createOffer + '/' + id + '/block', {}, {
            headers: {
                'accept': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('jwt')}`
            }
        })
            .then((res) => {
                console.log('res:::',res)
                handleShowModal(res?.data?.mag ? res?.data?.msg : 'Your offer has been paused.');
                setNavigateLink('');
                getAllOffers();
                setBlockLoader(false);

            })
            .catch((error) => {
                handleShowModal(error.response?.data?.message ? error.response?.data?.message : "Some Error Occured");
                setNavigateLink('');
            })
    }
    const handleUnBlockOffer = (id) => {
        setId(id);
        setUnblockLoader(true);
        axios.put(API_URLs.createOffer + '/' + id + '/unblock', {}, {
            headers: {
                'accept': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('jwt')}`
            }
        })
            .then((res) => {
                handleShowModal(res.data.mag ? res.data.msg : 'Offer resumed Successfully!!');
                setNavigateLink('');
                getAllOffers();
                setUnblockLoader(false);

            })
            .catch((error) => {
                handleShowModal(error.response?.data?.message ? error.response?.data?.message : "Some Error Occured");
                setNavigateLink('');
            })
    }

    const Loader = () => {
        return (
            <div className='d-flex my-4 justify-content-center'>
                <DataLoader />
            </div>
        )
    }

    return (
        <div className='myofferscreen'>
            <DataTable
                columns={columns}
                data={sellofferdata}
                customStyles={customStyles}
                progressPending={dataLoader}
                progressComponent={<Loader />}
                pagination
                theme='solarized'
            />
            
            {showModal && (<CustomModal
                show={showModal}
                handleClose={handleCloseModal}
                navigateLink={navigateLink}
                data={modalData}
            />
            )}
        </div>
    );
}