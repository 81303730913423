import React, { useEffect, useState } from "react";
import { useAuthContextHook } from "../../../src/Context/AuthContext";
import { AiOutlineArrowRight } from 'react-icons/ai'
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URLs } from "../../Utils/ApiUrls";
import { Oval } from "react-loader-spinner";

const CryptoTransactionSingle = ({ singleTransDetail, handleClose }) => {
    const [fromCurrency, to, toCurrency] = singleTransDetail?.realTransactionFromTo?.split(' ')
    const { userDetails } = useAuthContextHook();
    const [convertedRate,setConvertedRate] = useState(null)
    const [toCurrencyConvertedRate, setToCurrencyConvertedRate] = useState(null)
    const navigate = useNavigate();

    const convertCrypto = (from, to, amount=1) => {
        if (from && to) {
          axios.get(API_URLs.cryptoToFiat +"?from="+from+"&amount="+amount +"&target="+to, {
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              },
            })
            .then((res) => {
                console.log('row11Res::',res)
                setConvertedRate(res?.data)
            })
            .catch((error) => {
                console.log(error)
            });
        }
      };
    const convertCryptoTo = (from, to, amount=1) => {
        if (from && to) {
          axios.get(API_URLs.cryptoToFiat +"?from="+from+"&amount="+amount +"&target="+to, {
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              },
            })
            .then((res) => {
                console.log('row11Tocu::',res)
                setToCurrencyConvertedRate(res?.data)
            })
            .catch((error) => {
                console.log(error)
            });
        }
      };

      console.log('ccconvertedRate',convertedRate)
      console.log('row11From',singleTransDetail)
      console.log('row11Cuure',toCurrency)

      useEffect(()=>{
        convertCrypto(singleTransDetail?.currency, 'USD',1)
        convertCryptoTo(toCurrency , 'USD',1)
      },[singleTransDetail])

    //   useEffect(()=>{
    //   },[fromCurrency])

    return (
        <div className="px-4 py-2">
            <div className="d-flex flex-column gap-3">
                <div className="d-flex flex-wrap gap-2">
                    <div className="fw-bold w-25">Transaction ID : </div>
                    <div className="text-break">{singleTransDetail?.txnType === 'convert' ? singleTransDetail?.id : singleTransDetail?.transactionId}
                        {
                            singleTransDetail?.explorerUrl &&
                            <a target="_blank" rel="noreferrer" href={singleTransDetail?.explorerUrl}><AiOutlineArrowRight /></a>
                        }
                        {singleTransDetail.offertnxIds &&
                            <AiOutlineArrowRight size={17} color="blue" role="button"
                                onClick={() => navigate(`/trade/${singleTransDetail.offertnxIds.offer_id}/${singleTransDetail.offertnxIds.txn_hash}`)} />}
                    </div>
                </div>
                <div className="d-flex flex-wrap gap-2">
                    <div className="fw-bold w-25">Sender Name : </div>
                    <div className="text-break text-capitalize">{singleTransDetail?.fromUserId?.nickNames}</div>
                </div>
                <div className="d-flex flex-wrap gap-2">
                    <div className="fw-bold w-25">Sender Wallet Address : </div>
                    <div className="text-break text-capitalize">{singleTransDetail?.fromWalletId?.address}</div>
                </div>
                {singleTransDetail?.toUserId?.nickNames && <div className="d-flex flex-wrap gap-2">
                    <div className="fw-bold w-25">Receiver Name : </div>
                    <div className="text-break text-capitalize">{singleTransDetail?.toUserId?.nickNames}</div>
                </div>}
                {
                    singleTransDetail?.txnType === 'convert' ?
                        <>
                            <div className="d-flex flex-wrap gap-2">
                                <div className="fw-bold w-25">From : </div>
                                <div className="text-break text-capitalize">{fromCurrency}</div>
                            </div>
                            <div className="d-flex flex-wrap gap-2">
                                <div className="fw-bold w-25">To : </div>
                                <div className="text-break text-capitalize">{toCurrency}</div>
                            </div>
                        </>
                        :
                        <div className="d-flex flex-wrap gap-2">
                            <div className="fw-bold w-25">Receiver Wallet Address : </div>
                            <div className="text-break text-capitalize">{singleTransDetail?.receiverAddress ? singleTransDetail?.receiverAddress : singleTransDetail?.toWalletId?.address}</div>
                        </div>
                }
                {!(singleTransDetail?.txnType === 'convert') &&
                    <div className="d-flex flex-wrap gap-2">
                        <div className="fw-bold w-25">Currency : </div>
                        <div className="text-break">{singleTransDetail?.currency}</div>
                    </div>
                }
                <div className="d-flex flex-wrap gap-2">
                    <div className="fw-bold w-25">{singleTransDetail?.toUserId?.id === userDetails?.user?._id ? "Credited Amount :" : "Debited Amount :"} </div>
                    <div className="text-break">
                        <span>{singleTransDetail?.toUserId?._id === userDetails?.user?._id ? singleTransDetail?.receivedAmount : 
                            <>{(singleTransDetail?.transactionAmount + singleTransDetail?.adminFee)?.toFixed(10)}</>} {singleTransDetail?.currency}
                        </span>
                        <span> ≈ {singleTransDetail?.toUserId?._id === userDetails?.user?._id ? (Number(singleTransDetail?.receivedAmount||0)*convertedRate?.convertedRate || 0)?.toFixed(4) :
                             <>{(Number(singleTransDetail?.transactionAmount + singleTransDetail?.adminFee || 0)*convertedRate?.convertedRate || 0)?.toFixed(2)}</>} USD
                        </span>
                    </div>
                </div>
                {(singleTransDetail?.txnType === 'convert') ?
                    <div className="d-flex flex-wrap gap-2">
                        <div className="fw-bold w-25">Recieved Amount : </div>
                        <div className="text-break">{`${Number(singleTransDetail?.receivedAmount || 0)?.toFixed(10)} ${toCurrency} ≈ ${((Number(singleTransDetail?.receivedAmount || 0)*toCurrencyConvertedRate?.convertedRate)||0)?.toFixed(2)} USD`}</div>
                    </div> :
                    <div className="d-flex flex-wrap gap-2">
                        <div className="fw-bold w-25">Recieved Amount : </div>
                        <div className="text-break">{`${Number(singleTransDetail?.convertinUSD || 0)?.toFixed(10)} USD`}</div>
                    </div>
                }
                {
                    !(singleTransDetail?.toUserId?.id === userDetails?.user?._id) &&
                    <>
                        <div className="d-flex flex-wrap gap-2">
                            <div className="fw-bold w-25">Admin Fee : </div>
                            <div className="text-break d-flex flex-column flex-md-row gap-1">
                                <span>{Number(singleTransDetail?.adminFee || 0)?.toFixed(10)} {singleTransDetail?.currency}</span>
                                <span>≈ {((Number(singleTransDetail?.adminFee || 0)*convertedRate?.convertedRate) || 0)?.toFixed(2)} USD</span></div>
                        </div>

                        {!(singleTransDetail?.txnType === 'convert') && <div className="d-flex flex-wrap gap-2">
                            <div className="fw-bold w-25">Gas Fee : </div>
                            <div className="text-break">
                                <span>{Number(singleTransDetail?.gasfee || 0)?.toFixed(10)} {singleTransDetail?.currency === 'USDC' || singleTransDetail?.currency === 'USDT' ? 'ETH' : singleTransDetail?.currency}</span>
                                <span> ≈ {(Number(singleTransDetail?.gasfee || 0) * convertedRate?.convertedRate || 0)?.toFixed(2)} USD</span>
                            </div>
                        </div>}
                    </>
                }
                <div className="d-flex flex-wrap gap-2">
                    <div className="fw-bold w-25">Type : </div>
                    <div className="text-break">
                        {
                            singleTransDetail?.txnType === 'convert' ? <span style={{ color: 'rgb(141 13 220)' }}>Convert</span> :
                                singleTransDetail?.toUserId?.id === userDetails?.user?._id ? <div className="text-success fw-bold">Credited</div> : <div className='text-danger fw-bold'>Debited</div>
                        }
                    </div>
                </div>

                <div className="d-flex flex-wrap gap-2">
                    <div className="fw-bold w-25">Transaction Date : </div>
                    <div className="text-break">{new Date(singleTransDetail?.updatedAt).toDateString()}</div>
                </div>
            </div>
        </div>
    );
};

export default CryptoTransactionSingle;
