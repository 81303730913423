import React, { useState, useEffect, useCallback } from 'react'
import './CreateOffer.css'
import Select from 'react-select'
import Stepper from 'react-stepper-horizontal'
import Modal from 'react-bootstrap/Modal';
import { Bs1CircleFill, Bs2CircleFill,Bs3CircleFill,BsDot } from "react-icons/bs";
import * as Yup from 'yup'
import { useFormik } from 'formik';
import { GoDotFill } from "react-icons/go";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useThemeContext } from '../../Context/ThemeContext';
import { darkTheme, lightTheme } from "../../Theme/theme";
import AddBankAccount from '../PayMethod/AddBankAccount'
import { CardText, InputLabel, ProfileHeading } from '../Profile/StyledComponents'
import { BlueButton, WhiteButton, Input, InputContainer, P, OfferCard, PriceSide, SubCardContainer, TextArea } from './StyledComponents'
import axios from 'axios'
import { API_URLs } from '../../Utils/ApiUrls'
import {Button} from '../Wallet/StyledComponent'
import { useAPIContextHook } from '../../Context/ApiContext'
import { Oval } from 'react-loader-spinner'
import { useAuthContextHook } from '../../Context/AuthContext';
import { Steps} from 'antd';
import Card from '../PayMethod/Card'
import UPI from '../PayMethod/Upi'
import Paypal from '../PayMethod/Paypal'
import CustomModal from '../../hooks/CustomModal';

const horizintalStep = [
    { title: 'Payment Method' },
    { title: 'Pricing' },
    { title: 'Other Setting' },
];
export default function CreateOffer() {
    const { cryptoCurrencyOptions, fiatCurrencyOptions,fetchFiatCurrency, countryOptions, customFilterForCrypto, customFilterForCountry, paymentMethodList, fetchBankAccounts, fetchCardDetail, fetchUpiId, fetchPaypal, fetchPaymentMethods } = useAPIContextHook();
    const navigate = useNavigate();
    const { userDetails } = useAuthContextHook()
    const [bankAccountOptions, setBankAccountOptions] = useState([]);
    const [cardDetails, setCardDetails] = useState([])
    const [paypalDetails, setPaypalDetails] = useState([])
    const [upiList, setUpiList] = useState([]);
    const [isEdit, setIsEdit] = useState(false)
    const [isMargin, setIsMargin] = useState(true);
    const [submitLoader, setSubmitLoader] = useState(false)
    const [toFormik2, setToFormik2] = useState(false)
    const [toFormik3, setToFormik3] = useState(false)

    // limits and fees
    const [minTradeAmount, setMinTradeAmount] = useState('')
    const [qvaniFee, setQvaniFee] = useState('')

      // modal states
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [navigateLink, setNavigateLink] = useState('');
    const handleShowModal = (data) => {
        setModalData(data);
        setShowModal(true);
    };
    const handleCloseModal = () => {
        setShowModal(false)
    };

    // for select payment method acc to accordion
    const Step = Steps.Step;

    const params = useParams();
    const { id } = params;

    let handleFetchBankAccounts = async () => {
        try {
            let res = await fetchBankAccounts()
            let options = res?.length > 0 && res.map((bank) => {
                return {
                    ...bank,
                    value: bank?.id,
                    label: <div className='d-flex'>
                        <P className='m-0'>{bank?.accountNo}</P> -
                        <P className='m-0 text-uppercase'>{bank?.BankName}</P>
                    </div>
                }
            })
            setBankAccountOptions(options)
        } catch (error) {
        }
    }
    let handleFetchCardDetails = async () => {
        try {
            let res = await fetchCardDetail()
            
            let options = res?.length > 0 && res.map((card) => {
                return {
                    ...card,
                    value: card?._id,
                    label: <div className='d-flex'>
                        <P className='mb-0'>{card?.cardNo}</P> -
                        <P className='mb-0'>{card?.cardHolderName}</P>
                    </div>
                }
            })
            setCardDetails(options)
        }
        catch (error) {
            
        }
    }
    let fetchUpiIdFetch = async () => {
        try {
            let res = await fetchUpiId()
            
            let option = res?.length > 0 && res.map((upi) => {
                return {
                    ...upi,
                    value: upi?.id,
                    label: <div>
                        <P className='mb-0'>{upi?.upiId}<BsDot size={30} color={upi?.isActive ? 'green' : 'red'} /></P>
                    </div>
                }
            })
            setUpiList(option)
        } catch (error) {
            
        }
    }
    let handleFetchPaypalDetails = async () => {
        try {
            let res = await fetchPaypal()
            
            let options = res?.length > 0 && res.map((card) => {
                return {
                    ...card,
                    value: card?._id,
                    label: <div className='d-flex'>
                        <P className='mb-0'>{card?.authType}</P> -
                        { card?.authType === 'username' && <P className='mb-0'>{card?.username}</P> }
                        { card?.authType === 'mobile' && <P className='mb-0'>{card?.mobile}</P> }
                        { card?.authType === 'email' && <P className='mb-0'>{card?.email}</P> }
                    </div>
                }
            })
            setPaypalDetails(options)
        }
        catch (error) {
            
        }
    }
    useEffect(() => {
        handleSetNavbarHeading("Create Offer")
        if(paymentMethodList?.length ===0){
            fetchPaymentMethods()
        }
        if(fiatCurrencyOptions?.length === 0){
            fetchFiatCurrency();
        }
    }, [])

    const offerType = {
        buy: 'buy',
        sell: 'sell'
    }

    const cryptoRate = {
        market: 'market',
        fixed: 'fixed'
    }

    const getOfferInfo = useCallback(async () => {
        try {
            let res = await axios.get(API_URLs.getOfferDetailsById(id), {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('jwt')}`
                }
            })            
            setPaymentMethodState(res.data.offer.payment_method)
            setNext2(true)
            let currencyType = cryptoCurrencyOptions.filter((option) => {
                return option._id === res.data.offer.cryptoCurrency._id
            })
            let paymentMethod = paymentMethodList.filter((option) => {
                return option.value === res.data.offer.payment_method
            })
            let prefferedCurrency = fiatCurrencyOptions.filter((option) => {
                return option._id === res.data.offer.preffered_currency._id
            })
            let accountType = bankAccountOptions.filter((option) => {
                return option.value === res.data.offer.bank_account.id
            })
            let targetCountry = countryOptions.filter((option) => {
                return option.value === res.data.offer.target_country
            })
            let offerTagsArray = []
            Option.map((option) => {
                for (let i = 0; i < res.data?.offer?.offer_tags?.length; i++) {
                    const element = res.data?.offer?.offer_tags[i];
                    
                    if (option.value === element) {
                        offerTagsArray.push(option)
                    }
                }
            })
            

            formikStep1.setValues({
                currencyType: currencyType[0],
                prefferedCurrency: prefferedCurrency[0],
                paymentMethod: paymentMethod[0],
                accountType: accountType[0],
                offerType: res.data.offer.offer_type
            })
            formikStep2.setValues({
                pricingType: res.data.offer.trade_pricing_type,
                tradeMinAmount: res.data.offer.trade_limit.min,
                tradeMaxAmount: res.data.offer.trade_limit.max,
                margin: res.data.offer.offer_margin,
                timeLimit: res.data.offer.offer_time_limit
            })
            formikStep3.setValues({
                offerTag: offerTagsArray,
                offerLocation: targetCountry[0],
                tradeInstruction: res.data.offer.trade_instruction,
                verificationId: res.data.offer.verifications.VerifiedById,
                verificationName: res.data.offer.verifications.ShowFullname,
            })
        } catch (error) {
            
        }
    }, [id, bankAccountOptions])

    useEffect(() => {
        if (id) {
            getOfferInfo();
            setIsEdit(true);
        }
        else {
            setIsEdit(false);
        }
    }, [id, getOfferInfo])

    const [activeStep, setActiveStep] = useState(0);
    const [shownActiveSteps, setShownActiveSteps] = useState(1)
    const { isDarkTheme, handleSetNavbarHeading } = useThemeContext();
    const [next2, setNext2] = useState(false);
    const [show, setShow] = useState(false);
    const [rate, setRate] = useState('');
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [paymentMethodState, setPaymentMethodState] = useState('Bank Transfer')
    const Option = [
        { value: 'photo id required', label: 'photo id required' },
        { value: 'invoice are accepted', label: 'invoices are accepted' },
        { value: 'no receipt required', label: 'no receipt required' },
        { value: 'same bank only', label: 'same bank only' },
        { value: 'receipt required', label: 'receipt required' },
        { value: 'no third parties', label: 'no third parties' },
        { value: 'no verification needed', label: 'no verification needed' },
    ]
    const [selectedOption, setSelectedOption] = useState(null)
    const formikStep1 = useFormik({
        initialValues: {
            currencyType: cryptoCurrencyOptions[0] || "",
            offerType: offerType.sell,
            paymentMethod: "",
            prefferedCurrency: "",
            accountType: "",
        },
        validationSchema: Yup.object().shape({
            currencyType: Yup.object().shape({
                value: Yup.string().required('Currency Type is Required'),
            }),
            offerType: Yup.string().required('Offer Type is Required'),
            paymentMethod: Yup.object().shape({
                value: Yup.string().required('Payment Method is Required'),
            }),
            prefferedCurrency: Yup.object().shape({
                value: Yup.string().required('Currency is Required'),
            }),
            accountType: Yup.object().shape({
                value: Yup.string(),
            }),
        }),
        onSubmit: values => {
            
            convertHandle(formikStep1.values.currencyType._id, formikStep1.values.prefferedCurrency.value)
            setActiveStep(activeStep + 1);
            setToFormik2(true)

        }
    })

    // calling function for data
    useEffect(()=>{
        if(paymentMethodState === "Bank Transfer"){
            handleFetchBankAccounts()
        }
        else if(paymentMethodState === "Credit/Debit Card"){
            handleFetchCardDetails()
        }
        else if(paymentMethodState === "UPI Transfer"){
            fetchUpiIdFetch()
        }
        else if(paymentMethodState === "PayPal"){
            handleFetchPaypalDetails()
        }
    },[formikStep1.values.paymentMethod])

    const handleGetMinTradeLimit = async (currencyId) => {
        await axios.get(API_URLs.getMinTradeLimit(currencyId.toString()), {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.getItem('jwt')}`
            }
        })
            .then((res) => {
                
                setMinTradeAmount(res.data.rate)
            })
            .catch((error) => {
                
            })
    }

    const formikStep2 = useFormik({
        initialValues: {
            pricingType: cryptoRate.market,
            tradeMinAmount: '',
            tradeMaxAmount: '',
            margin: '5',
            fixedPrice: '',
            timeLimit: ''
        },
        validationSchema: () => Yup.lazy((values) => Yup.object().shape({
            pricingType: Yup.string().required('Price Type is Required'),
            tradeMinAmount: Yup.number().min(Math.round(minTradeAmount), `Amount must be greater then ${Math?.round(minTradeAmount)}`).required('Minimum Amount is Required'),
            tradeMaxAmount: Yup.number().min(values.tradeMinAmount, `Amount must be greater than ${values?.tradeMinAmount}`).required('Max Amount is Required'),
            margin: Yup.number().required('Margin is Required').min(-25, 'Margin must be greater than or equal to -25').max(40, 'Margin must be less than or equal to 40'),
            timeLimit: Yup.number().required('Time Limit is Required')
                .test('is-not-decimal', 'Value cannot be a decimal number', (value) => {
                    return Number.isInteger(value);
                })
                .min(30, 'Time Limit must be greater than or equal to 30').max(90, 'Time Limit must be less than or equal to 90'),
        })
        ),
        onSubmit: (values) => {
            setActiveStep(activeStep + 1);            
            setToFormik3(true)
        }
    })

    const formikStep3 = useFormik({
        initialValues: {
            offerTag: [],
            tradeInstruction: '',
            offerLocation: '',
            verificationId: false,
            verificationName: false,
        },
        validationSchema: Yup.object().shape({
            tradeInstruction: Yup.string().max(2500, 'Maximum 2500 character allowed').required('Trade Instruction is Required'),
            offerLocation: Yup.object().shape({
                value: Yup.string().required('Offer Location is Required')
            })
        }),
        onSubmit: async (values) => {
            if (isEdit) {
                try {
                    setSubmitLoader(true)
                    let res = await axios.put(API_URLs.createOffer + '/' + id, {
                        cryptoCurrency: formikStep1.values.currencyType?.value,
                        offer_type: formikStep1.values.offerType,
                        payment_method: formikStep1.values.paymentMethod?.value,
                        preffered_currency: formikStep1.values.prefferedCurrency?.value,
                        bank_account: formikStep1.values.accountType?.value,
                        trade_pricing_type: formikStep2.values.pricingType,
                        trade_limit: {
                            min: formikStep2.values.tradeMinAmount,
                            max: formikStep2.values.tradeMaxAmount
                        },
                        ...(isMargin === true ?
                            {
                                offer_margin: formikStep2?.values?.margin,
                            }
                            :
                            { fixed_offer_price: formikStep2?.values?.fixedPrice }),
                        offer_time_limit: String(formikStep2.values.timeLimit).toString(),
                        ...(formikStep3?.values?.offerTag?.length > 0 &&
                            {
                                offer_tags: formikStep3.values.offerTag?.map((tag) => tag.value)
                            }),
                        trade_instruction: formikStep3.values.tradeInstruction,
                        verifications: {
                            VerifiedById: formikStep3.values.verificationId,
                            ShowFullname: formikStep3.values.verificationName
                        },
                        target_country: formikStep3.values.offerLocation?.value,
                    }, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('jwt')}`
                        }
                    })
                    setSubmitLoader(false)
                    setModalData(res);
                    setNavigateLink('/dashboard/myoffer')
                    handleShowModal(res?.data?.message ? res?.data?.message : 'Offer created Successfully!!');
                }
                catch (error) {
                    setSubmitLoader(false)
                    setModalData(error);
                    setNavigateLink('');
                    handleShowModal(error?.response?.data?.message ? error?.response?.data?.message : 'Some Error Occured!!'); 
                }
            }
            else {
                try {
                    setSubmitLoader(true)
                    let res = await axios.post(API_URLs.createOffer, {
                        cryptoCurrency: formikStep1.values.currencyType?.value,
                        offer_type: formikStep1.values.offerType,
                        payment_method: formikStep1.values.paymentMethod?.value,
                        preffered_currency: formikStep1.values.prefferedCurrency?.value,
                        bank_account: formikStep1.values.accountType?.value,
                        trade_pricing_type: formikStep2.values.pricingType,
                        trade_limit: {
                            min: formikStep2.values.tradeMinAmount,
                            max: formikStep2.values.tradeMaxAmount
                        },
                        ...(isMargin === true ?
                            { offer_margin: formikStep2?.values?.margin }
                            :
                            { fixed_offer_price: formikStep2?.values?.fixedPrice }),
                        offer_time_limit: String(formikStep2.values.timeLimit).toString(),
                        offer_tags: formikStep3.values.offerTag?.map((tag) => tag.value),
                        trade_instruction: formikStep3.values.tradeInstruction,
                        verifications: {
                            VerifiedById: formikStep3.values.verificationId,
                            ShowFullname: formikStep3.values.verificationName
                        },
                        target_country: formikStep3.values.offerLocation?.value,
                    }, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('jwt')}`
                        }
                    })
                    setSubmitLoader(false)
                    setNavigateLink('/dashboard/myoffer')
                    handleShowModal(res?.data?.msg ? res?.data?.msg : 'Offer Created Successfully!!');
                }
                catch (error) {
                    setSubmitLoader(false)
                    setModalData(error);
                    handleShowModal(error?.response?.data?.message ? error?.response?.data?.message : 'Some Error Occured');
                    setNavigateLink('')
                    
                }
            }

        }
    })
    const convertHandle = async (crypto, fiat) => {
        try {
            let res = await axios.get(API_URLs.cryptoToFiat + '?from=' + crypto + '&amount=1' + '&target=' + fiat)
            setRate(res.data.convertedRate)
        } catch (error) {
            
        }
    }

    const styles = {
        control: (styles) => ({
            ...styles,
            background: 'transparent',
            borderRadius: '52px',
            color: 'white',
            boxShadow: `${isDarkTheme ? darkTheme.boxshadow : lightTheme.boxshadow}`,
            border: `${isDarkTheme ? darkTheme.border : lightTheme.border}`, "&:hover": {
                border: `${isDarkTheme ? darkTheme.border : lightTheme.border}`
            },
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: isDarkTheme && state.isHovered ? '#302F30' : `${({theme})=> theme.body}`,
            ':hover': {
              backgroundColor:  `${isDarkTheme ? '#302F30' : '#D9EDE7'}` ,
            },
            color: isDarkTheme && state.isSelected ? '#1476FF' : `${({theme})=> theme.text}`,
        }),
        indicatorSeparator: () => ({
            all: 'unset'
        }),
        indicatorsContainer: (styles) => ({
            ...styles, color: isDarkTheme ? 'white' : 'black', 'div:nth-child(2)': {
                color: isDarkTheme ? 'white' : lightTheme.text
            }
        }),
        menu: (styles) => ({
            ...styles, background: isDarkTheme ? 'rgb(27, 27, 28)' : 'white',
            color: isDarkTheme ? 'white' : lightTheme.text,
        })
    }
    
    function getSectionComponent() {
        switch (activeStep) {
            case 0: return (
                <div >
                    <div className='d-flex mb-4 gap-1 align-items-center'>
                        <Bs1CircleFill color='blue' size={20} />
                        <p className='mb-0'>Choose Cryptocurrency and Payment Methods</p>
                    </div>
                    <form className='pb-1' onSubmit={formikStep1.handleSubmit}>
                        <div className='row m-0' >
                            <div className='col-md-12'>
                                <div className='row m-0 mb-5'>
                                    <div className='col-md-7 px-0 choosecrypto'>
                                        <InputLabel htmlFor='currencyType'>Choose Crypto Currency</InputLabel>
                                        <Select className="select"
                                            width='100%'
                                            name='currencyType'
                                            id='currencyType'
                                            options={cryptoCurrencyOptions}
                                            styles={styles}
                                            onChange={(selectedValues) => {
                                                let event = { target: { name: 'currencyType', value: selectedValues } }
                                                formikStep1.handleChange(event)
                                            }}
                                            onBlur={(selectedValues) => {
                                                let event = { target: { name: 'currencyType', value: selectedValues } }
                                                formikStep1.handleBlur(event)
                                            }}
                                            value={formikStep1.values.currencyType}
                                        />
                                        <small>
                                            {formikStep1.errors.currencyType && formikStep1.touched.currencyType && (
                                                <div className="text-danger text-start">{String(formikStep1.errors.currencyType?.value).replace('.value', '')}</div>
                                            )}
                                        </small>
                                    </div>
                                </div>
                                <div className='row m-0 mb-4 chooseoffertype'>
                                    <InputLabel className=' px-0 d-flex gap-2 align-items-center' htmlFor='offerType'>
                                        I want to
                                    </InputLabel>
                                    <div className='col-md-6 mb-2'>
                                        <SubCardContainer onClick={() => {
                                            formikStep1.setValues({
                                                ...formikStep1.values,
                                                offerType: offerType.sell
                                            })
                                        }} className={`${formikStep1.values.offerType === offerType.sell && 'border border-primary'} d-flex flex-row py-3 align-items-center`} >
                                            <input
                                                name='offerType'
                                                id='offerType'
                                                onChange={() => {
                                                    formikStep1.setValues({
                                                        ...formikStep1.values,
                                                        offerType: offerType.sell
                                                    })
                                                }}
                                                onBlur={formikStep1.handleBlur}
                                                className='m-2'
                                                checked={formikStep1.values.offerType === offerType.sell}
                                                type='radio'
                                                />
                                            <div className='d-flex flex-column'>
                                                <InputLabel>Sell {formikStep1.values?.currencyType?.name}</InputLabel>
                                            </div>
                                        </SubCardContainer>
                                    </div>
                                    <div className='col-md-6'>
                                        <SubCardContainer onClick={() => {
                                            formikStep1.setValues({
                                                ...formikStep1.values,
                                                offerType: offerType?.buy
                                            })
                                        }} className={`${formikStep1.values.offerType === offerType.buy && 'border border-primary'} d-flex flex-row py-3 align-items-center`}>
                                            <input
                                                name='offerType'
                                                id='offerType'
                                                onChange={() => {
                                                    formikStep1.setValues({
                                                        ...formikStep1.values,
                                                        offerType: offerType?.buy
                                                    })
                                                }}
                                                onBlur={formikStep1.handleBlur}
                                                className='m-2'
                                                checked={formikStep1.values.offerType === offerType?.buy}
                                                type='radio'/>
                                            <div className='d-flex flex-column'>
                                                <InputLabel >Buy {formikStep1.values?.currencyType?.name}</InputLabel>
                                            </div>
                                        </SubCardContainer>
                                    </div>
                                    <small>
                                        {formikStep1.errors.offerType && formikStep1.touched.offerType && (
                                            <div className="text-danger text-start">{formikStep1.errors.offerType}</div>
                                        )}
                                    </small>
                                </div>
                                <div className='col-md-7 mb-4 paymentmethod'>
                                    <InputLabel htmlFor='paymentMethod mb-2'>Choose Payment Method</InputLabel>
                                    <Select className="select"
                                        name='paymentMethod'
                                        id='paymentMethod'
                                        width='100%'
                                        options={paymentMethodList}
                                        styles={styles}
                                        onChange={(selectedValues) => {
                                            let event = { target: { name: 'paymentMethod', value: selectedValues } }
                                            formikStep1.handleChange(event)
                                            setPaymentMethodState(selectedValues.value)
                                            setQvaniFee(selectedValues.fee)
                                            setShownActiveSteps(shownActiveSteps + 1)
                                        }}
                                        onBlur={(selectedValues) => {
                                            let event = { target: { name: 'paymentMethod', value: selectedValues } }
                                            formikStep1.handleBlur(event)
                                        }}
                                        value={formikStep1?.values.paymentMethod}    
                                    />

                                    <small>
                                        {formikStep1?.errors?.paymentMethod && formikStep1.touched.paymentMethod && (
                                            <div className="text-danger text-start">{String(formikStep1.errors.paymentMethod?.value).replace('.value', '')}</div>
                                        )}
                                    </small>
                                </div>
                                <div className='col-md-7 mb-4 prefferedCurrency'>
                                    <InputLabel htmlFor='prefferedCurrency mb-2'>Choose Currency</InputLabel>
                                    <Select className="select"
                                        name='prefferedCurrency'
                                        id='prefferedCurrency'
                                        width='100%'
                                        options={fiatCurrencyOptions}
                                        styles={styles}
                                        filterOption={customFilterForCrypto}
                                        onChange={(selectedValues) => {
                                            let event = { target: { name: 'prefferedCurrency', value: selectedValues } }
                                            formikStep1.handleChange(event)
                                            setNext2(true)
                                            setShownActiveSteps(shownActiveSteps + 1);
                                            handleGetMinTradeLimit(selectedValues?._id)
                                        }}
                                        onBlur={(selectedValues) => {
                                            let event = { target: { name: 'prefferedCurrency', value: selectedValues } }
                                            formikStep1.handleBlur(event)
                                        }}
                                        value={formikStep1.values.prefferedCurrency}
                                    />
                                    <small>
                                        {formikStep1.errors.prefferedCurrency && formikStep1.touched.prefferedCurrency && (
                                            <div className="text-danger text-start">{String(formikStep1.errors.prefferedCurrency?.value).replace('.value', '')}</div>
                                        )}
                                    </small>
                                </div>
                                {(paymentMethodState === 'UPI Transfer' || paymentMethodState === 'Credit/Debit Card'
                                    || paymentMethodState === 'Bank Transfer'
                                    || paymentMethodState === 'PayPal') && <div className='row m-0 mb-5'>
                                        {
                                            next2 &&
                                                <div className='col-md-7 px-0 chooseacounttype'>
                                                    {paymentMethodState === "UPI Transfer" &&
                                                        <><div className='d-flex justify-content-between'>
                                                            <InputLabel htmlFor='accountType'>UPI ID</InputLabel>
                                                            <Link type="button" onClick={handleShow}>Add Your UPI</Link>
                                                        </div>
                                                            <Select className="select"
                                                                width='100%'
                                                                name="accountType"
                                                                id='accountType'
                                                                options={upiList || []}
                                                                styles={styles}
                                                                onChange={(selectedValues) => {
                                                                    let event = { target: { name: 'accountType', value: selectedValues } }
                                                                    formikStep1.handleChange(event)
                                                                }}
                                                                onBlur={(selectedValues) => {
                                                                    let event = { target: { name: 'accountType', value: selectedValues } }
                                                                    formikStep1.handleBlur(event)
                                                                }}
                                                                value={formikStep1.values.accountType}
                                                            />
                                                            <small>
                                                                {formikStep1.errors.accountType && formikStep1.touched.accountType && (
                                                                    <div className="text-danger text-start">{String(formikStep1.errors.accountType?.value).replace('.value', '')}</div>
                                                                )}
                                                            </small>
                                                        </>}
                                                    {
                                                        paymentMethodState === 'Credit/Debit Card' &&
                                                        <>
                                                            <div className='d-flex justify-content-between'>
                                                                <InputLabel htmlFor='accountType'>Card Detail</InputLabel>
                                                                <Link type='button' onClick={handleShow}>Add Card Details</Link>
                                                            </div>
                                                            <Select className="select"
                                                                width='100%'
                                                                name="accountType"
                                                                options={cardDetails || []}
                                                                id='accountType'
                                                                styles={styles}
                                                                onChange={(selectedValues) => {
                                                                    let event = { target: { name: 'accountType', value: selectedValues } }
                                                                    formikStep1.handleChange(event)
                                                                }}
                                                                onBlur={(selectedValues) => {
                                                                    let event = { target: { name: 'accountType', value: selectedValues } }
                                                                    formikStep1.handleBlur(event)
                                                                }}
                                                                value={formikStep1.values.accountType}
                                                            />
                                                            <small>
                                                                {formikStep1.errors.accountType && formikStep1.touched.accountType && (
                                                                    <div className="text-danger text-start">{String(formikStep1.errors.accountType?.value).replace('.value', '')}</div>
                                                                )}
                                                            </small>
                                                        </>
                                                        }
                                                    {paymentMethodState === 'Bank Transfer' &&
                                                        <>
                                                            <div className='d-flex justify-content-between'>
                                                                <InputLabel htmlFor='accountType'>Bank Account</InputLabel>
                                                                <Link type="button" onClick={handleShow}>Add Bank Account</Link>
                                                            </div>
                                                            <Select className="select"
                                                                width='100%'
                                                                name="accountType"
                                                                id='accountType'
                                                                options={bankAccountOptions || []}
                                                                styles={styles}
                                                                onChange={(selectedValues) => {
                                                                    let event = { target: { name: 'accountType', value: selectedValues } }
                                                                    formikStep1.handleChange(event)
                                                                }}
                                                                onBlur={(selectedValues) => {
                                                                    let event = { target: { name: 'accountType', value: selectedValues } }
                                                                    formikStep1.handleBlur(event)
                                                                }}
                                                                value={formikStep1.values.accountType}
                                                            />
                                                            <small>
                                                                {formikStep1.errors.accountType && formikStep1.touched.accountType && (
                                                                    <div className="text-danger text-start">{String(formikStep1.errors.accountType?.value).replace('.value', '')}</div>
                                                                )}
                                                            </small>
                                                        </>}
                                                    {paymentMethodState === 'PayPal' &&
                                                        <>
                                                            <div className='d-flex justify-content-between'>
                                                                <InputLabel htmlFor='accountType'>PayPal</InputLabel>
                                                                <Link type="button" onClick={handleShow}>Add your Paypal</Link>
                                                            </div>
                                                            <Select className="select"
                                                                width='100%'
                                                                name="accountType"
                                                                id='accountType'
                                                                options={paypalDetails || []}
                                                                styles={styles}
                                                                onChange={(selectedValues) => {
                                                                    let event = { target: { name: 'accountType', value: selectedValues } }
                                                                    formikStep1.handleChange(event)
                                                                }}
                                                                onBlur={(selectedValues) => {
                                                                    let event = { target: { name: 'accountType', value: selectedValues } }
                                                                    formikStep1.handleBlur(event)
                                                                }}
                                                                value={formikStep1.values.accountType}
                                                            />
                                                            <small>
                                                                {formikStep1.errors.accountType && formikStep1.touched.accountType && (
                                                                    <div className="text-danger text-start">{String(formikStep1.errors.accountType?.value).replace('.value', '')}</div>
                                                                )}
                                                            </small>
                                                        </>}
                                                </div>}
                                    </div>}
                            </div>
                        </div>

                        <div className='row m-0 mb-2'>
                            <div className='col-md-12 text-end'>
                                <BlueButton type='submit'
                                    onClick={() => {
                                        
                                    }}
                                >Next</BlueButton>
                            </div>
                        </div>
                    </form>
                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header style={{ background: isDarkTheme ? '#0c0c0c' : 'white'}} closeButton>
                            <Modal.Title style={{ color: isDarkTheme ? 'white' : 'black'}}>Add Bank</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ background: isDarkTheme ? '#0c0c0c' : 'white'}}>
                            {paymentMethodState === 'UPI Transfer' && <UPI handleClose={handleClose} handleSetRefresh={fetchUpiIdFetch} />}
                            {paymentMethodState === "PayPal" && <Paypal handleClose={handleClose} handleSetRefresh={handleFetchPaypalDetails} />}
                            {paymentMethodState === 'Credit/Debit Card' && <Card handleClose={handleClose} handleSetRefresh={handleFetchCardDetails} />}
                            {paymentMethodState === 'Bank Transfer' && <AddBankAccount handleClose={handleClose} handleSetRefresh={handleFetchBankAccounts} />}
                        </Modal.Body>
                    </Modal>
                </div>
            );
            case 1: return (
                <div>
                    <div className='d-flex mb-4 gap-1 align-items-center'>
                        <Bs2CircleFill color='blue' size={20} />
                        <p className='mb-0'>Trade Rate</p>
                    </div>
                    <form onSubmit={formikStep2.handleSubmit}>
                        <div className='row m-0'>
                            <div className='col-md-12'>
                                <div className='row m-0 mb-5 gap-1 gap-md-0 pricingtype'>
                                    <InputLabel htmlFor='pricingType' className='mb-1'>{formikStep1.values?.currencyType?.name} Price Rate</InputLabel>
                                    <div className='col-md-6'>
                                        <SubCardContainer onClick={() => {
                                            formikStep2.setValues({
                                                ...formikStep2.values,
                                                pricingType: cryptoRate.market
                                            })
                                            setIsMargin(true);
                                        }} className={`d-flex flex-row py-2 align-items-start h-100 ${formikStep2.values.pricingType === cryptoRate.market ? 'border border-primary' : ''}`} >
                                            <input
                                                name='pricingType'
                                                id='pricingType'
                                                onChange={() => {
                                                    formikStep2.setValues({
                                                        ...formikStep2.values,
                                                        pricingType: cryptoRate.market
                                                    })
                                                    setIsMargin(true);
                                                }}
                                                checked={formikStep2.values.pricingType === cryptoRate.market}
                                                onBlur={formikStep2.handleBlur}
                                                className='m-2'
                                                type='radio'
                                                />
                                            <div className='d-flex flex-column'>
                                                <InputLabel className='mb-0'>Market price</InputLabel>
                                                <CardText className='text-secondary'>Your offer’s selling price will change according to the market price of {formikStep1.values?.currencyType?.name}.</CardText>
                                            </div>
                                        </SubCardContainer>

                                    </div>
                                    <div className='col-md-6'>
                                        <SubCardContainer onClick={() => {
                                            formikStep2.setValues({
                                                ...formikStep2.values,
                                                pricingType: cryptoRate.fixed
                                            })
                                            setIsMargin(false);
                                        }} className={`d-flex flex-row py-2 align-items-start h-100 ${formikStep2.values.pricingType === cryptoRate.fixed ? 'border border-primary' : ''}`} >
                                            <input
                                                name='pricingType'
                                                id='pricingType'
                                                onChange={() => {
                                                    formikStep2.setValues({
                                                        ...formikStep2.values,
                                                        pricingType: cryptoRate.fixed
                                                    })
                                                    setIsMargin(false);
                                                }}
                                                checked={formikStep2.values.pricingType === cryptoRate.fixed}
                                                onBlur={formikStep2.handleBlur}
                                                className='m-2'
                                                type='radio'
                                                />
                                            <div className='d-flex flex-column'>
                                                <InputLabel className='mb-0'>Fixed price</InputLabel>
                                                <CardText className='text-secondary'>Your offer’s selling price is locked when you create it, and won’t change with the market price.</CardText>
                                            </div>
                                        </SubCardContainer>
                                    </div>
                                    <small>
                                        {formikStep2.errors.pricingType && formikStep2.touched.pricingType && (
                                            <div className="text-danger text-start">{formikStep2.errors.pricingType}</div>
                                        )}
                                    </small>
                                </div>
                                <div className='row m-0 mb-5'>
                                    <div className='col-md-7 col-sm-12 d-flex flex-column mt-1'>
                                        <div className='d-flex flex-row flex-wrap justify-content-start'>
                                            <InputLabel htmlFor='margin' className='mb-2'>{isMargin ? 'Offer Margin' : 'Fixed Rate'}</InputLabel><br />
                                            {
                                                isMargin ?
                                                    <div className='offermargin'>
                                                        <InputContainer className='d-flex p-1'>
                                                            <Input
                                                                className='flex-grow-1 text-center'
                                                                name='margin'
                                                                id='margin'
                                                                onBlur={formikStep2.handleBlur}
                                                                onChange={formikStep2.handleChange}
                                                                value={formikStep2.values.margin} />
                                                            <PriceSide className='px-4 py-1'>%</PriceSide>
                                                        </InputContainer>
                                                        <div className='d-flex justify-content-between gap-2'>
                                                            <small>
                                                                {formikStep2.errors.margin && formikStep2.touched.margin && (
                                                                    <div className="input-feedback text-danger auth-error text-start text-wrap">{formikStep2.errors.margin}</div>
                                                                )}
                                                            </small>
                                                            <P className='text-end '>on each trade</P>
                                                        </div>
                                                    </div> :
                                                    <div>
                                                        <InputContainer className='d-flex p-1'>
                                                            <Input
                                                                className='flex-grow-1 text-center'
                                                                name='fixedPrice'
                                                                id='fixedPrice'
                                                                onBlur={formikStep2.handleBlur}
                                                                onChange={formikStep2.handleChange}
                                                                value={formikStep2.values.fixedPrice} />

                                                            <PriceSide className='px-4 py-1'>{formikStep1.values?.prefferedCurrency?.name}</PriceSide>
                                                        </InputContainer>
                                                        <div className='d-flex justify-content-between gap-2'>
                                                            <small>
                                                                {formikStep2.errors.fixedPrice && formikStep2.touched.fixedPrice && (
                                                                    <div className="input-feedback text-danger auth-error text-start text-wrap">{formikStep2.errors.fixedPrice}</div>
                                                                )}
                                                            </small>
                                                            <P className='text-end '>on each trade</P>
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                        <P className='mb-0'>Current <strong>{formikStep1.values.currencyType.symbol}</strong> market price = <strong>{Number(rate).toFixed(2)} {formikStep1.values.prefferedCurrency.name}</strong></P>
                                        {/* {isMargin && <P className='mb-0'> You are {formikStep1.values.offerType} <strong>{formikStep2.values.margin}%</strong> above market price.</P>} */}
                                    </div>
                                </div>
                                <div className='row m-0 mb-5'>
                                    <div className='col-xl-4 col-sm-12 d-flex align-items-end'>
                                        <InputLabel className='fs-5'>Offer Trade Limits</InputLabel><br />
                                    </div>
                                    <div className='col-xl-8 col-sm-12 d-flex flex-column'>
                                        <div className='d-flex flex-row gap-2 justify-content-between'>
                                            <div className='flex-grow-1 minimumtradelimit'>
                                                <InputLabel htmlFor='tradeMinAmount'>Minimum Amount</InputLabel>
                                                <InputContainer className='d-flex justify-content-between p-1'>
                                                    <Input
                                                        className='flex-grow-1'
                                                        name='tradeMinAmount'
                                                        placeholder='Enter Minimum Amount'
                                                        id='tradeMinAmount'
                                                        value={formikStep2.values.tradeMinAmount}
                                                        onChange={formikStep2.handleChange}
                                                        onBlur={formikStep2.handleBlur}
                                                        type='text' 
                                                    />
                                                    <PriceSide className='px-2 py-1'>{formikStep1.values.prefferedCurrency.name}</PriceSide>
                                                </InputContainer>
                                                <small>
                                                    {formikStep2.errors.tradeMinAmount && formikStep2.touched.tradeMinAmount && (
                                                        <div className="text-danger text-start">{formikStep2.errors.tradeMinAmount}</div>
                                                    )}
                                                </small>
                                            </div>
                                            <div className='flex-grow-1 maximumtradelimit'>
                                                <InputLabel htmlFor='tradeMaxAmount'>Maximum Amount</InputLabel>
                                                <InputContainer className='d-flex justify-content-between p-1'>
                                                    <Input
                                                        name='tradeMaxAmount'
                                                        id='tradeMaxAmount'
                                                        className='flex-grow-1'
                                                        value={formikStep2.values.tradeMaxAmount}
                                                        onBlur={(event) => {
                                                            
                                                            formikStep2.handleBlur(event)
                                                            setShownActiveSteps(shownActiveSteps + 1)
                                                        }}
                                                        onChange={formikStep2.handleChange}
                                                        type='text'
                                                        placeholder='Enter maximum amount' 
                                                    />
                                                    <PriceSide className='px-2 py-1'>{formikStep1.values.prefferedCurrency.name}</PriceSide>
                                                </InputContainer>
                                                <small>
                                                    {formikStep2.errors.tradeMaxAmount && formikStep2.touched.tradeMaxAmount && (
                                                        <div className="text-danger text-start">{formikStep2.errors.tradeMaxAmount}</div>
                                                    )}
                                                </small>
                                            </div>                                           
                                        </div>
                                    </div>
                                </div>
                                <div className='row m-0 mb-5'>
                                    <div className='col-md-7 col-sm-12 d-flex flex-column'>
                                        <div className='d-flex flex-row flex-wrap justify-content-start setofferminutes'>
                                            <div>
                                                <InputLabel htmlFor='timeLimit'>Payment Time Limit</InputLabel>
                                                <InputContainer className='d-flex p-1'>
                                                    <Input
                                                        className='flex-grow-1 text-center'
                                                        name='timeLimit'
                                                        id='timeLimit'
                                                        onChange={formikStep2.handleChange}
                                                        value={formikStep2.values.timeLimit}
                                                        onBlur={formikStep2.handleBlur}
                                                    />
                                                    <span className='p-1'>Minutes</span>
                                                </InputContainer>
                                            </div>
                                        </div>
                                        <small>
                                            {formikStep2.errors.timeLimit && formikStep2.touched.timeLimit && (
                                                <div className="text-danger text-start">{formikStep2.errors.timeLimit}</div>
                                            )}
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row m-0 d-flex justify-content-center align-items-center mb-4'>
                            <div className='col-md-12 text-center gap-4 col-sm-12 d-flex flex-row justify-content-between align-items-center'>
                                <WhiteButton type='button'
                                    onClick={() => {
                                        setActiveStep(activeStep - 1)
                                        setToFormik2(false)
                                    }}>Back</WhiteButton>
                                <BlueButton type='submit'>Next</BlueButton>
                            </div>
                        </div>
                    </form>
                </div>
            );
            case 2: return (
                <div>
                    <div className='d-flex mb-3 gap-1 align-items-center'>
                        <Bs3CircleFill color='blue' size={20} />
                        <p className='mb-0'>Other Settings</p>
                    </div>
                    <form onSubmit={formikStep3.handleSubmit}>
                        <div className='row m-0 d-flex justify-content-center align-item-center'>
                            <div className='col-md-12'>
                                <div className='row m-0 mb-5'>
                                    <div className='col-md-11 d-flex flex-column'>
                                        <InputLabel htmlFor='tradeInstruction'>Trade Instructions</InputLabel>
                                        <div>
                                            <TextArea
                                                rows={5}
                                                name='tradeInstruction'
                                                id='tradeInstruction'
                                                value={formikStep3.values.tradeInstruction}
                                                onChange={formikStep3.handleChange}
                                                onBlur={formikStep3.handleBlur}
                                                placeholder='List out your instructions for your trade partner.'
                                                className='px-2 TextArea p-3 w-100'></TextArea>
                                        </div>
                                        <small>
                                            {formikStep3.errors.tradeInstruction && formikStep3.touched.tradeInstruction && (
                                                <div className="text-danger text-start">{formikStep3.errors.tradeInstruction}</div>
                                            )}
                                        </small>
                                    </div>
                                </div>
                                <div className='row m-0 mb-5'>
                                    <div className='col-md-7 youroffertags'>
                                        <InputLabel htmlFor='offerTag'>Offer Tags</InputLabel>
                                        <Select
                                            isMulti
                                            styles={styles}
                                            name='offerTag'
                                            id='offerTag'
                                            options={Option?.filter((option)=>{
                                                if(formikStep3?.values?.offerTag?.some((item)=>item?.value?.includes("no receipt required"))){
                                                    return option?.value != "receipt required";
                                                }
                                                else if(formikStep3?.values?.offerTag?.some((item)=>item?.value?.includes("receipt required"))){
                                                    return option?.value != "no receipt required";
                                                }
                                                else{
                                                    return option;
                                                }
                                            })}
                                            placeholder='Offer Tags'
                                            onChange={(selectedValues) => {
                                                let event = { target: { name: 'offerTag', value: selectedValues } }
                                                formikStep3.handleChange(event)
                                            }}
                                            onBlur={(selectedValues) => {
                                                let event = { target: { name: 'offerTag', value: selectedValues } }
                                                formikStep3.handleBlur(event)
                                            }}
                                            value={formikStep3.values.offerTag}
                                        />
                                    </div>
                                </div>
                                <div className='row m-0 mb-5'>
                                    <div className='col-md-7 youroffertags'>
                                        <InputLabel htmlFor='offerLocation'>Offer Location</InputLabel>
                                        <Select
                                            required
                                            styles={styles}
                                            name='offerLocation'
                                            filterOption={customFilterForCountry}
                                            id='offerLocation'
                                            options={countryOptions}
                                            placeholder='Offer Location'
                                            onChange={(selectedValues) => {
                                                let event = { target: { name: 'offerLocation', value: selectedValues } }
                                                formikStep3.handleChange(event)
                                            }}
                                            onBlur={(selectedValues) => {
                                                let event = { target: { name: 'offerLocation', value: selectedValues } }
                                                formikStep3.handleBlur(event)
                                            }}
                                            value={formikStep3?.values?.offerLocation}
                                        />
                                        <small>
                                            {formikStep3.errors.offerLocation && formikStep3.touched.offerLocation && (
                                                <div className="text-danger text-start">{String(formikStep3.errors.offerLocation.value).replace('.value', '')}</div>
                                            )}
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row m-0 mb-4 d-flex justify-content-center align-items-center mt-0'>
                            <div className='col-md-12 text-center gap-4 col-sm-12 d-flex flex-row justify-content-between align-items-center'>
                                <WhiteButton onClick={() => {
                                    setActiveStep(activeStep - 1)
                                    setToFormik3(false)
                                }}>Back</WhiteButton>
                                <BlueButton disabled={submitLoader} type='submit'>{
                                    submitLoader ?
                                        <Oval
                                            height={18}
                                            width={18}
                                            color="black"
                                            wrapperStyle={{}}
                                            wrapperClass=""
                                            visible={true}
                                            ariaLabel="oval-loading"
                                            secondaryColor="#4fa94d"
                                            strokeWidth={2}
                                            strokeWidthSecondary={2}
                                        /> :
                                        isEdit ? 'Update' : 'Create Offer'}</BlueButton>
                            </div>
                        </div>
                    </form>
                </div>
            );
            default: return (<p>Submited!!</p>);
        }
    }
    return (
        <div className='container px-2 py-4'>

            <div className='row m-0 d-flex justify-content-center'>
                <OfferCard className='card col-md-7 col-sm-12'>
                    <div className='d-sm-block d-md-none'>
                        <Stepper steps={horizintalStep} activeStep={activeStep} />
                    </div>
                    <ProfileHeading className='py-4 d-flex gap-2 text-start text-md-center word-break-word'>Create an Offer To <>{formikStep1.values.offerType}</> <>{formikStep1.values.currencyType?.name}</></ProfileHeading>
                    {getSectionComponent()}
                </OfferCard>
                <OfferCard className={`col-md-4 d-md-block d-none ${isDarkTheme && 'blackmode-on'} card py-3 px-5`}>
                    <Steps direction='vertical'>
                        <Step status='finish' title="" icon={<Bs1CircleFill />}/>
                        <Step status={formikStep1.values.offerType && 'finish'} title={`You want to ${formikStep1.values.offerType} ${formikStep1.values.currencyType.name}`} icon={<GoDotFill size={14}/>} />
                        <Step status={formikStep1.values.paymentMethod && 'finish'} title={`You will pay ${formikStep1.values.paymentMethod ? formikStep1.values.offerType === 'sell' ? `${qvaniFee}%` : "0%" : "0%"} Qvani fee`} icon={<GoDotFill size={14}/>}/>
                        {formikStep1.values.paymentMethod?.label && <Step status={formikStep1.values.prefferedCurrency && 'finish'} title={`And get paid via ${formikStep1.values.paymentMethod?.label} in ${formikStep1.values.prefferedCurrency?.name ? formikStep1.values.prefferedCurrency?.name : ''}`} icon={<GoDotFill size={14}/>}/>}
                        <Step title="" status={toFormik2 && 'finish'} icon={<Bs2CircleFill />}  />
                        {isMargin ? <Step status={toFormik2 && formikStep2.values.margin.length > 0 && 'finish'} title={formikStep1.values.offerType === "sell" ? `You’ll make a profit of ${formikStep2.values.margin}% on every trade` : `You will pay ${formikStep2.values.margin}% above market price on every purchase`} icon={<GoDotFill size={14}/>}/> : 
                         <Step status={toFormik2 && formikStep2.values.fixedPrice.length > 0 && 'finish'} title={`You're ${formikStep1.values.offerType}ing this ${formikStep1.values.currencyType.name} at rate of ${formikStep2.values.fixedPrice} ${formikStep1.values.prefferedCurrency?.name}`} icon={<GoDotFill size={14}/>}/>}
                        <Step status={toFormik2 && formikStep2?.values?.tradeMaxAmount.length > 0 && 'finish'} title={`Trade Limit between ${formikStep2.values.tradeMinAmount ? `${formikStep2.values.tradeMinAmount} and` : ''} ${formikStep2.values.tradeMinAmount && formikStep2.values.tradeMaxAmount ? formikStep2.values.tradeMaxAmount : ''} ${formikStep2.values.tradeMaxAmount ? formikStep1.values.prefferedCurrency?.name : ''}`} icon={<GoDotFill size={14}/>}/>
                        <Step status={formikStep2.values.timeLimit && 'finish'} title={`You have ${formikStep2.values.timeLimit} minutes to complete the trade.`} icon={<GoDotFill size={14} />} />
                        <Step title="" status={toFormik3 && 'finish'} icon={<Bs3CircleFill />} />
                        {formikStep3.values.offerLocation && <Step status={toFormik3 && formikStep3.values.offerLocation && 'finish'} title={`This offer is from ${formikStep3?.values?.offerLocation && formikStep3?.values?.offerLocation?.name}`} icon={<GoDotFill size={14}/>}/>}
                    </Steps>
                </OfferCard>
            </div>
            
            {showModal && (<CustomModal
                show={showModal}
                handleClose={handleCloseModal}
                navigateLink={navigateLink}
                data={modalData}
            />)}
        </div>
    );
}