import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import './Navbar.css'
import { Link, useNavigate } from 'react-router-dom';
import { useAuthContextHook } from '../../Context/AuthContext';
import { useThemeContext } from '../../Context/ThemeContext';
import { FaRegBell } from "react-icons/fa6";
import { FaUserAlt } from "react-icons/fa";
import { MdOutlineSecurity, MdSendToMobile, MdAutoGraph, MdOutlineHeadphones } from "react-icons/md";
import LogOutIcon from '../../assets/LogOutIcon';
import { HiOutlineMenuAlt4 } from 'react-icons/hi'
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { API_URLs } from '../../Utils/ApiUrls';
import { HiOutlineUser } from "react-icons/hi2";
import { AiOutlineSetting } from "react-icons/ai";
import { useProSidebar } from 'react-pro-sidebar';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { RegisterButton, TourButton, NotificationContainer, NotificationHeading, NotificatonLabel, Span, NotificationHeader, NotificationBody, Badge } from './NavStyleComponent'

const dropdownOption = [
    {
        name: 'My Profile',
        link: '/dashboard/settings#profile',
        img: <HiOutlineUser color='#1474FF' />
    },
    {
        name: 'Dashboard',
        link: '/dashboard/user-dashboard',
        img: <MdAutoGraph color='#1474FF' /> },
    {
        name: 'Settings',
        link: '/dashboard/settings#profile',
        img: <AiOutlineSetting color='#1474FF' />
    },
    { 
        name: 'Security',
        link: '/dashboard/settings#security',
        img: <MdOutlineSecurity color='#1474FF' />
    },
    {
        name: 'Invite a Friend',
        link: '/dashboard/settings#your-reffers',
        img: <MdSendToMobile color='#1474FF' />
    },
    {
        name: 'Support',
        link: '//support.qvani.com/support/login ',
        external: true,
        img: <MdOutlineHeadphones color='#1474FF' />
    },
]

function NavbarComponent({ handleShowJoy }) {
    const navigate = useNavigate()
    const { isDarkTheme, direction, screenWidth } = useThemeContext();
    const { IsLogedIn, handleLogout, userDetails } = useAuthContextHook();
    const [notificationsData, setNotificationsData] = useState([]);
    const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);

    const getNotifications = async () => {
        try {
            let res = await axios.get(API_URLs.getNotifications, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('jwt')}`
                }
            })

            let arr = [];
            for (let key in res.data?.data?.notifications) {
                let single = res.data?.data?.notifications[key];
 
                if (arr.length <= 5) {
                    for (let key1 in single) {
                        if (arr.length <= 5) {
                            arr.push(single[key1])
                        }
                    }
                }
            }
            setNotificationsData(arr)
            setUnreadNotificationCount(res.data?.data?.unreadNotificaitonCount)
        } catch (error) {
        }
    }

    const markAsRead = async (notification) => {
        try {
            await axios.patch(API_URLs.readNotification(notification?._id), {}, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('jwt')}`
                }
            })

            getNotifications()
            if (notification?.transactionType && notification?.transactionIds) {
                let pathname = notification.transactionIds
                    .map((res) => res?.id ? String(res.id) + "/" : "")
                    .join("");
                if (pathname.endsWith("/")) {
                    pathname = pathname.slice(0, -1);
                }

                if (notification?.notificationType === 'transaction') {
                    let urlNew = `/dashboard/${notification.transactionType}/${pathname}`;
                    navigate(urlNew);
                } else {
                    let urlNew = `/${notification.transactionType}/${pathname}`;
                    navigate(urlNew);
                }
                
            }
        } catch (error) {
            console.log('Error', error);
        }
    }

    useEffect(() => {
        if (localStorage.getItem('jwt')) {
            getNotifications();
            const interval = setInterval(getNotifications, 30000);
            return () => clearInterval(interval);
        }
    }, []);
    

    const markAllAsRead = async () => {
        try {
            await axios.patch(API_URLs.readAllNotifications, {}, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('jwt')}`
                }
            })
            getNotifications()
        } catch (error) {
            console.log('Error', error);
        }
    }
    const { collapseSidebar, toggleSidebar } = useProSidebar();

    return (
        <>
            <Navbar className='navbar-style py-0' expand="lg"
                style={{
                    minHeight: '66px',
                    background: `${isDarkTheme ? 'black' : 'white'}`,
                    borderBottom: `${isDarkTheme ? '1px solid #666565' : 'none'}`,
                    width: 'calc(100% - 1px)',
                    zIndex: '2',
                    position: 'sticky',
                    top: '0',
                }}>
                <div className='container-fluid me-4'>
                    <div className='d-flex gap-1 align-items-center'>
                        <HiOutlineMenuAlt4
                            size="20"
                            className='cursor-pointer'
                            onClick={() => {
                                if (screenWidth <= 992) {
                                    toggleSidebar();
                                } else {
                                    collapseSidebar();
                                }
                            }}

                        />
                    </div>
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                        </Nav>
                        <Nav className='align-items-lg-center'>
                            <Nav.Link className='ps-2 '>
                                <TourButton size={24} title='take a tour' onClick={() => handleShowJoy()}>Take a Tour</TourButton>
                            </Nav.Link>
                            {
                                !IsLogedIn && <>
                                    <Nav.Link as={Link} to='/login'>
                                        <RegisterButton className='px-4 py-1'>Login</RegisterButton>
                                    </Nav.Link>
                                    <Nav.Link as={Link} to='/register'>
                                        <RegisterButton className='px-4 py-1'>Register</RegisterButton>
                                    </Nav.Link>
                                </>
                            }
                        </Nav>
                    </Navbar.Collapse>
                    <div className='d-flex align-items-center gap-2'>
                        <Nav.Link className='px-2'>
                            <OverlayTrigger
                                trigger="click"
                                placement={'bottom'}
                                rootClose
                                overlay={
                                    <Popover id={`popover-positioned-bottom`} style={{ minWidth: '300px', border: `${isDarkTheme ? '1px solid grey' : 'none'}` }}>
                                        <Popover.Header as="div"
                                            style={{
                                                ...(
                                                    isDarkTheme ?
                                                        {
                                                            background: 'black',
                                                            color: 'white'
                                                        } :
                                                        {
                                                            background: '#f0f0f0',
                                                            color: 'black'
                                                        }
                                                )
                                            }}
                                        >
                                            <div className='d-flex justify-content-between align-items-end'>
                                                <NotificationHeading className='m-0'>Notifications</NotificationHeading>
                                                <NotificatonLabel
                                                    onClick={markAllAsRead}
                                                    className='m-0 text-primary'>Mark All as Read
                                                </NotificatonLabel>
                                            </div>
                                        </Popover.Header>
                                        <Popover.Body className={`p-0 ${isDarkTheme ? 'popover-body-black' : 'popover-body-white'}`}>
                                            {
                                                notificationsData?.length > 0 ? notificationsData?.map((notification) => {
                                                    return (
                                                        <NotificationContainer
                                                            style={{
                                                                ...(
                                                                    isDarkTheme &&
                                                                    {
                                                                        background: '#101010',
                                                                        color: 'white'
                                                                    }
                                                                )
                                                            }}
                                                            key={uuidv4()}
                                                            onClick={() => {
                                                                markAsRead(notification)
                                                                if (notification?.transactionType === "trade/") {
                                                                    navigate(`${notification?.transactionType}${notification?.transactionIds[0]?.id}/${notification?.transactionIds[1]?.id}`)
                                                                }
                                                                else if (notification?.notificationType === "transaction") {
                                                                    navigate('/dashboard/wallet')
                                                                }
                                                            }}
                                                            active={notification?.status === 'unread'}
                                                        >
                                                            <NotificationHeader className='m-0'>{notification?.notificationTitle}</NotificationHeader>
                                                            <NotificationBody className='m-0'>{notification?.notificationBody}</NotificationBody>
                                                        </NotificationContainer>
                                                    )
                                                }) : <NotificationHeader className='d-flex justify-content-center pt-3'>No Notifications Yet</NotificationHeader>
                                            }
                                            <div className='d-flex justify-content-end'>
                                                <NotificatonLabel as={Link} to='/dashboard/notifications'
                                                    className='m-0 text-center py-2 px-2'>View All Notifications
                                                </NotificatonLabel>
                                            </div>
                                        </Popover.Body>
                                    </Popover>
                                }
                            >
                                <button className='pt-1 d-flex' style={{ all: 'unset' }}>
                                    <FaRegBell size={20} />
                                    {
                                        !!unreadNotificationCount &&
                                        <Badge pill bg="success">
                                            {unreadNotificationCount}
                                        </Badge>
                                    }
                                </button>
                            </OverlayTrigger>
                        </Nav.Link>
                        <Nav.Link className='px-2'>
                            {
                                userDetails?.user?.avatar ?
                                    <div style={{ width: '40px', height: '40px', borderRadius: '100%', overflow: "hidden" }}>
                                        <img className='w-100 h-100' src={userDetails?.user?.avatar} alt="avatar" />
                                    </div> :
                                    <FaUserAlt size={20} />
                            }
                        </Nav.Link>
                        <NavDropdown
                            className={`rounded-2 ${isDarkTheme ? 'black' : 'white'}`}
                            align={`${direction === 'rtl' ? 'start' : 'end'}`}
                            title={<Span >{userDetails?.user?.nickNames}</Span>} id="collasible-nav-dropdown-4">
                            {
                                dropdownOption.map((data, index) => {
                                    return data.external ?
                                    
                                    (<div key={uuidv4()}><NavDropdown.Item as='a' href={data.link} target="_blank" className='p-2 d-flex align-items-top gap-1 justify-content-start'>
                                        <div>{data.img}</div>
                                        <span className='Link text-secondary' >{data.name}</span>
                                    </NavDropdown.Item></div>) 
                                    : 
                                    (<div key={uuidv4()}><NavDropdown.Item as={Link} to={data.link} className='p-2 d-flex align-items-top gap-1 justify-content-start'>
                                    <div>{data.img}</div>
                                    <span className='Link text-secondary' >{data.name}</span>
                                </NavDropdown.Item></div>)
                                })
                            }
                            <NavDropdown.Item className='p-2 d-flex align-items-center gap-1 justify-content-start' onClick={handleLogout}>
                                <LogOutIcon fill={'red'} />
                                <span className='Link text-danger'>Log Out</span>
                            </NavDropdown.Item>
                        </NavDropdown>
                    </div>
                </div>
            </Navbar >
        </>
    );
}
NavbarComponent.propTypes = {
    handleShowJoy: PropTypes.func.isRequired,
};
export default NavbarComponent;
