import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import './Wallet.css'
import styled from 'styled-components';
import { MdOutlineAccountBalanceWallet } from 'react-icons/md'
import { BsFillLightningChargeFill, BsWallet } from 'react-icons/bs'
import { SiConvertio } from 'react-icons/si'
import CryptoBalance from './CryptoBalance';
import Address from './Address'
import Convert from './Convert'
import Lightning from './Lightning'
import { TabNavigationButton } from '../Profile/StyledComponents';
import CryptoTransaction from './CryptoTransaction';

const Heading = styled.p`
    font-size: 28px;
    font-weight: 700;
`
export default function WalletPage() {
    const location = useLocation();
    const navigate = useNavigate();

    const isTabSelected = location.hash.includes('balance')
        // || location.hash.includes('lightning')
        || location.hash.includes('address')
        || location.hash.includes('convert')

    return (
        <div className='row m-0 mt-3'>
            <div className='col-md-12'>
                <Heading className='mb-0'>Wallet</Heading>
                <div className='tabs-navigator'>
                    <ul className="nav nav-tabs crypto-send d-flex gap-sm-3 gap-1" id="myTab" role="tablist">
                        <li className="nav-item text-decoration-none">
                            <TabNavigationButton
                                className={`nav-link gap-1 ${isTabSelected ? location.hash.includes('balance') ? 'active' : '' : 'active'}`}
                                id="balance-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#balance"
                                type="button"
                                role="tab"
                                aria-controls="balance"
                                onClick={() => navigate('#balance')}
                                aria-selected="true">
                                <MdOutlineAccountBalanceWallet />
                                Balance
                            </TabNavigationButton>
                        </li>
                        {/* <li className="nav-item text-decoration-none">
                            <TabNavigationButton
                                className={`nav-link gap-1 ${isTabSelected ? location.hash.includes('lightning') ? 'active' : '' : ''}`}
                                id="lightning-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#lightning"
                                type="button"
                                role="tab"
                                aria-controls="lightning"
                                onClick={() => navigate('#lightning')}
                                aria-selected="true">
                                <BsFillLightningChargeFill />
                                Lightning
                            </TabNavigationButton>
                        </li> */}
                        <li className='nav-item text-decoration-none'>
                            <TabNavigationButton
                                className={`nav-link  gap-1 ${isTabSelected ? location.hash.includes('address') ? 'active' : '' : ''}`}
                                id='address-tab'
                                data-bs-toggle='tab'
                                data-bs-target='#address'
                                type='button'
                                role='tab'
                                aria-controls='address'
                                aria-selected='true'
                                onClick={() => navigate('#address')}
                            >
                                <BsWallet />
                                Addresses
                            </TabNavigationButton>
                        </li>
                        <li className="nav-item text-decoration-none">
                            <TabNavigationButton
                                className={`nav-link  gap-1 ${isTabSelected ? location.hash.includes('convert') ? 'active' : '' : ''}`}
                                id="convert-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#convert"
                                type="button"
                                role="tab"
                                aria-controls="convert"
                                onClick={() => navigate('#convert')}
                                aria-selected="false">
                                <SiConvertio />
                                Convert
                            </TabNavigationButton>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div
                            className={`tab-pane fade py-3 ${isTabSelected ? location.hash.includes('balance') ? 'show active' : '' : 'show active'}`}
                            id="balance"
                            role="tabpanel"
                            aria-labelledby="balance-tab">
                            <CryptoBalance shown={true}/>
                            <CryptoTransaction shown={true}/>
                        </div>
                        {/* <div
                            className={`tab-pane fade py-3 ${isTabSelected ? location.hash.includes('lightning') ? 'show active' : '' : ''}`}
                            id="lightning"
                            role="tabpanel"
                            aria-labelledby="lightning-tab">
                            {location.hash.includes('lightning') && <Lightning />}
                        </div> */}
                        <div
                            className={`tab-pane fade py-3 ${isTabSelected ? location.hash.includes('address') ? 'show active' : '' : ''}`}
                            id="address"
                            role="tabpanel"
                            aria-labelledby="address-tab">
                            {location.hash.includes('address') && <Address />}
                        </div>
                        <div
                            className={`tab-pane fade py-3 ${isTabSelected ? location.hash.includes('convert') ? 'show active' : '' : ''}`}
                            id="convert"
                            role="tabpanel"
                            aria-labelledby="convert-tab">
                            {location.hash.includes('convert') && <Convert />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
