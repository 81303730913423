import React, { useState } from 'react'
import { ProfileHeading } from '../Profile/StyledComponents'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { AiOutlineInfoCircle } from 'react-icons/ai'
import axios from 'axios';
import { API_URLs } from '../../Utils/ApiUrls';
import ButtonLoader from '../../Component/Loader/ButtonLoader';
import { useAuthContextHook } from '../../Context/AuthContext';
import styled from 'styled-components';
import { useThemeContext } from '../../Context/ThemeContext';
import CustomModal from '../../hooks/CustomModal';

const Errorbox = styled.span`
width:75%;
font-size: 14px;
background: ${({ theme }) => theme.body === "#F7F9FC" ? "#F8F8F8" : theme.body};
color: ${({ theme }) => theme.text === "#121620" ? theme.text : "#e0dada"};
`
const InputLabel = styled.label`
    font-size: 14px;
    color: ${({ theme }) => theme.text};
    font-weight: 600;
`
const Input = styled.input`
    all: unset;
    background: transparent;
    border: ${({ theme }) => theme.border};
    box-shadow: ${({ theme }) => theme.boxshadow};
    outline: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: ${({ theme }) => theme.text === "#121620" ? "#33343c" : theme.text};
    cursor: initial;

    :disabled{
        opacity: 0.8;
        color: ${({ theme }) => theme.text === "#121620" ? "#6F7182" : theme.text};
    }
`
const Button = styled.button`
    border: 1px solid darkgrey;
    padding: 6px 27px;
    border-radius : 6px;
`

export default function ChangePassword() {
    const [oldPasswordTypeText, setOldPasswordTypeText] = useState(false);
    const [newPasswordTypeText, setNewPasswordTypeText] = useState(false);
    const [reEnterNewPasswordTypeText, setReEnterNewPasswordTypeText] = useState(false);
    const [loader, setLoader] = useState(false);
    const { isDarkTheme } = useThemeContext();
    const { handleLogout } = useAuthContextHook();


    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [navigateLink, setNavigateLink] = useState('');


    const handleShowModal = (data)=>{
        setModalData(data);
        setShowModal(true);
    }
    
    
    const handleCloseModal = ()=>{
        setShowModal(false);
    }


    const toggleOldPasswordType = () => {
        setOldPasswordTypeText(!oldPasswordTypeText)
    }
    const toggleNewPasswordType = () => {
        setNewPasswordTypeText(!newPasswordTypeText)
    }
    const toggleReEnterNewPasswordType = () => {
        setReEnterNewPasswordTypeText(!reEnterNewPasswordTypeText)
    }

    const formik = useFormik({
        initialValues: { oldPassword: '', newPassword: '', reNewPassword: '' },
        validationSchema: Yup.object({
            // oldPassword: Yup.string()
            //     .min(8, "").max(16).required('Old Password is Required')
            //     .matches(/[A-Z]/, 'atleast 1 uppercase letter required')
            //     .matches(/[a-z]/, 'atleast 1 lowercase letter required')
            //     .matches(/[0-9]/, 'atleast 1 number required')
            //     .matches(/[!@#$%^&*]/, 'atleast 1 special character required'),

            newPassword: Yup.string().required('New Password is Required')
                .min(8, "Password must be minimum 8 characters")
                .matches(/[A-Z]/, 'atleast 1 uppercase letter required')
                .matches(/[a-z]/, 'atleast 1 lowercase letter required')
                .matches(/[0-9]/, 'atleast 1 number required')
                .matches(/[!@#$%^&*]/, 'atleast 1 special character required'),
            reNewPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Confirm password should be same as New  password')
        }),
        onSubmit: values => {
            setLoader(true)
            axios.patch(API_URLs.changePassword, {
                oldPassword: values.oldPassword,
                newPassword: values.newPassword,
                cnewPassword: values.reNewPassword
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('jwt')}`,
                    'Content-Type': 'application/json'
                }
            })
                .then((res) => {
                    setLoader(false)

                    handleShowModal(res?.data?.response?.msg ? res?.data?.response?.msg : 'Password Changed Successfully!!')
                    handleLogout()
                    formik.resetForm()
                })
                .catch((error) => {
                    setLoader(false)

                    handleShowModal(error?.response?.data?.message ? error?.response?.data?.message : 'Some Error Occured')
                })
        }
    })

    return (
        <>
            <div className='d-flex justify-content-start'>
                <div
                    style={{
                        ...(
                            isDarkTheme ?
                                {
                                    border: '1px solid rgb(35 36 36)',
                                    boxshadow: '5px 6px 10px rgb(35 36 36)',
                                } :
                                {
                                    border: '2px solid #dee2e6',
                                    boxShadow: '0px 0px 2px #dee2e6'
                                }
                        )
                    }}
                    className={`p-4 col-md-12`}>
                    <ProfileHeading>Change Password</ProfileHeading>
                    <Errorbox>If You forgot your current password, logout, go to Login screen and click <strong>Forgot Password</strong></Errorbox>
                    <form onSubmit={formik.handleSubmit} className='d-flex flex-column gap-3 mt-3'>
                        <div className='col-md-6'>
                            <div className='d-flex flex-column gap-1'>
                                <InputLabel htmlFor='oldPassword'>Current Password</InputLabel>
                                <div className='d-flex w-100 '>
                                    <Input
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.oldPassword}
                                        type={oldPasswordTypeText ? 'text' : 'password'}
                                        placeholder='Enter Your Old Password Here'
                                        className='px-3 py-2 w-100'
                                        name='oldPassword'
                                    />
                                    <div className='d-flex align-items-center'>
                                        {
                                            oldPasswordTypeText ?
                                                <FaEye onClick={toggleOldPasswordType} style={{ marginLeft: '-30px' }} className='position-absolute' /> :
                                                <FaEyeSlash onClick={toggleOldPasswordType} style={{ marginLeft: '-30px' }} className='position-absolute' />
                                        }
                                    </div>
                                </div>

                                {formik.touched.oldPassword && formik.errors.oldPassword ? (
                                    <div className='text-danger text-danger-error user-validation'>{formik.errors.oldPassword}</div>
                                ) : null}
                            </div>

                            <div className='d-flex flex-column gap-1'>
                                <InputLabel htmlFor='newPassword'>New Password</InputLabel>
                                <div className='d-flex w-100'>
                                    <Input
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.newPassword}
                                        type={newPasswordTypeText ? 'text' : 'password'}
                                        placeholder='Enter Your New Password Here'
                                        className='px-3 py-2 w-100'
                                        name='newPassword'
                                    />
                                    <div className='d-flex align-items-center'>
                                        {
                                            newPasswordTypeText ?
                                                <FaEye onClick={toggleNewPasswordType} style={{ marginLeft: '-30px' }} className='position-absolute' /> :
                                                <FaEyeSlash onClick={toggleNewPasswordType} style={{ marginLeft: '-30px' }} className='position-absolute' />
                                        }
                                    </div>
                                </div>
                                {formik.touched.newPassword && formik.errors.newPassword ? (
                                    <div className='text-danger text-danger-error user-validation'>{formik.errors.newPassword}</div>
                                ) : null}
                            </div>

                            <div className='d-flex flex-column gap-1'>
                                <InputLabel htmlFor='reNewPassword'>Confirm New Password</InputLabel>
                                <div className='d-flex w-100'>
                                    <Input
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.reNewPassword}
                                        type={reEnterNewPasswordTypeText ? 'text' : 'password'}
                                        placeholder='Confirm New Password Here'
                                        className='px-3 py-2 w-100'
                                        name='reNewPassword'
                                    />
                                    <div className='d-flex align-items-center'>
                                        {
                                            reEnterNewPasswordTypeText ?
                                                <FaEye onClick={toggleReEnterNewPasswordType} style={{ marginLeft: '-30px' }} className='position-absolute' /> :
                                                <FaEyeSlash onClick={toggleReEnterNewPasswordType} style={{ marginLeft: '-30px' }} className='position-absolute' />
                                        }
                                    </div>
                                </div>

                                {formik.touched.reNewPassword && formik.errors.reNewPassword ? (
                                    <div className='text-danger text-danger-error user-validation'>{formik.errors.reNewPassword}</div>
                                ) : null}
                            </div>

                        </div>
                        <div className='d-flex gap-3 flex-wrap align-items-center'>
                            <Button disabled={loader} type='submit'>
                                {
                                    loader ? <div className='d-flex'> <ButtonLoader /> <span>changing..</span></div> : 'Change Password'
                                }
                            </Button>
                            <small className='text-secondary'><AiOutlineInfoCircle size={20} />Changing your password will log you out of all your active sessions.</small>
                        </div>
                    </form>

                </div>
            </div>
            {showModal && (<CustomModal
                show={showModal}
                handleClose={handleCloseModal}
                navigateLink={navigateLink}
                data={modalData}
            />
            )}
        </>
    )
}
